import { useEffect, useState } from "react";
import { useTheme, styled } from "@mui/material/styles";
import Site from "../Site";
import {
  Button,
  TextareaAutosize,
  Input,
  TextField,
  NumberInput,
} from "@mui/material";

import {
  CREATE_EMAIL_HISTORY,
  EMAIL_HISTORIES,
  GET_LEADFORMS,
  GET_DAY_MESSAGE,
  UPDATE_DAY_MESSAGE,
} from "../gql/leadForm";

import { useMutation, useQuery } from "@apollo/client";
import client from "../apollo/client";
import ContactDetail from "../components/crm/ContactDetail";

require("./css/crm.css");

const Crm = () => {
  const [user, setuser] = useState(false);

  const [createEmailHistory] = useMutation(CREATE_EMAIL_HISTORY);

  const [updateDayMsg] = useMutation(UPDATE_DAY_MESSAGE);
  const theme = useTheme();

  const { data, loading, error } = useQuery(GET_LEADFORMS);

  const [list, setList] = useState([]);

  const [emailHistory, setEmailHistory] = useState([]);

  useEffect(() => {
    setuser(window.localStorage.getItem("strapi_user"));

    if (!loading) {
      if (!error) {
        if (data) {
          console.log(data);
          const d = JSON.parse(JSON.stringify(data));
          setList(
            d?.leadFormSubmissions?.data
              .sort((a, b) => {
                if (
                  a?.attributes?.name.toLowerCase() <
                  b?.attributes?.name.toLowerCase()
                ) {
                  return -1;
                }
              })
              .filter((l) => !emailHistory.includes(l.attributes.email))
          );
        }
      }
    }
  }, [data, error, loading]);

  const {
    data: emaildata,
    loading: emailloading,
    error: emailerror,
  } = useQuery(EMAIL_HISTORIES);

  useEffect(() => {
    if (!emailloading) {
      if (!emailerror) {
        if (emaildata) {
          console.log(emaildata);
          setEmailHistory(
            emaildata.emailHistories.data
              .map((d) => d.attributes.email)
              .reverse()
          );
        }
      }
    }
  }, [emaildata, emailloading, emailerror]);

  const [historySize, setHistorySize] = useState(0);
  useEffect(() => {
    setHistorySize(emailHistory.length);
    const _list = list;
    setList(
      _list
        .filter((l) => !emailHistory.includes(l.attributes.email))
        .sort((a, b) => {
          if (
            a?.attributes?.name.toLowerCase() <
            b?.attributes?.name.toLowerCase()
          ) {
            return -1;
          }
        })
    );
  }, [emailHistory]);

  const updateDay_Msg = async (info, day, template) => {
    await updateDayMsg({
      variables: {
        dance_day: day,
        message: info,
        template: template,
      },
    })
      .then((res) => {
        console.log(res);
        client.refetchQueries({
          include: "active",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendEmail = async (contact) => {
    console.log(contact);

    try {
      await createEmailHistory({
        variables: {
          email: contact.attributes.email,
          name: contact.attributes.name,
          leadFormId: Number(contact.id),
          info: theme.infoMessage,
          day: theme.infoDay,
          template: theme.template,
        },
      });

      // Refetch the active queries
      await client.refetchQueries({
        include: "active",
      });

      // Return 'done' to indicate success
      return "done";
    } catch (err) {
      // Return or throw the error if something goes wrong
      return err;
    }
  };

  const CRMList = ({ info }) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          // position: "relative",
          // marginTop: 300,
          // marginBottom: 300,
          // border: "1px solid green",
        }}
      >
        <Sent />
        {
          list.map((contact, key) => {
            // console.log(contact);
            //   if (contact.attributes.email !== "richard.lanham@gmail.com") {
            //     return null;
            //   }
            return (
              <div
                className="contacts"
                key={key}
                style={{
                  // display: "flex",
                  // flexwrap: "wrap",
                  // gap: 10,
                  width: 320,
                  backgroundColor: theme.palette.warning.light,
                }}
              >
                <div>
                  <Button onClick={() => sendEmail(contact)}>send</Button>
                  <font style={{ ...theme.typography.caption }}>
                    sent:{historySize}
                  </font>
                </div>
                <div className="col">{contact.attributes.name}</div>
                <div className="col">{contact.attributes.email}</div>
                <div
                  style={{
                    ...theme.typography.caption1,
                    display: "flex",
                    flexwrap: "wrap",
                    // gap: 20,
                    // border: "1px solid black",
                    // maxHeight: 20,
                  }}
                >
                  {contact.attributes.last_email_date !== null && (
                    <div>
                      {new Date(
                        contact.attributes.last_email_date
                      ).toDateString()}
                      &nbsp;
                      {new Date(
                        contact.attributes.last_email_date
                      ).toLocaleTimeString()}
                    </div>
                  )}
                </div>
                <ContactDetail id={key} contact={contact} />
              </div>
            );
          })
          //   .sort((a, b) => {
          //     if (a.attributes.name < b.attributes.name) {
          //       return -1;
          //     }
        }
      </div>
    );
  };

  const Sent = () => {
    return (
      <div
        style={{
          // position: "fixed",
          // left: "60%",
          // top: 450,
          maxHeight: 300,
          border: "1px solid black",
          overflow: "scroll",
        }}
      >
        <font style={{ ...theme.typography.h5 }}>sent:{historySize}</font>

        {emailHistory.map((contact, key) => {
          // console.log(contact);
          return <div key={key}>{contact}</div>;
        })}
      </div>
    );
  };

  const Controls = () => {
    const [info, setInfo] = useState("");
    const [day, setDay] = useState("");
    const [template, setTemplate] = useState("");
    theme.infoDay = day;
    theme.infoMessage = info;
    theme.template = template;
    const handleInfo = (e) => {
      console.log(e.target.value);
      setInfo(e.target.value);
      theme.infoMessage = e.target.value;
    };

    const handleDay = (e) => {
      setDay(e.target.value);
      theme.infoDay = e.target.value;
    };

    const handleTempate = (e) => {
      setTemplate(e.target.value);
      theme.template = e.target.value;
    };

    // const { data, loading, error } = useQuery(GET_LEADFORMS);

    const {
      data: msgdata,
      loading: msgloading,
      error: msgerror,
    } = useQuery(GET_DAY_MESSAGE);

    useEffect(() => {
      console.log(msgloading);
      if (!msgloading) {
        if (!msgerror) {
          if (msgdata) {
            if (msgdata.crm.data) {
              console.log(msgdata.crm.data.attributes.message);
              setInfo(msgdata.crm.data.attributes.message);
              setDay(msgdata.crm.data.attributes.dance_day);
              setTemplate(msgdata.crm.data.attributes.template);
            }
          }
        }
      }
    }, [msgdata, msgerror, msgloading]);
    const [updateCount, setUpdateCount] = useState(
      localStorage.getItem("updateCount") || 10
    );

    const [countDown, setCountDown] = useState(updateCount);
    const [countUp, setCountUp] = useState(0);

    const sendBatch = async () => {
      console.log(list[0]);

      for (var i = 0; i < updateCount; i++) {
        sendEmail(list[i]);
      }

      // if (response === "done") {
      //   if (countDown > 0) {
      //     setCountDown((prev) => prev - 1);
      //     setCountUp((prev) => prev + 1);
      //     setTimeout(() => sendBatch(), 2000);
      //   }
      // } else {
      //   console.error("Error sending email:", response);
      //   // sendBatch();
      // }
    };

    const doUpdateCount = (event) => {
      setUpdateCount(event.target.value);
      localStorage.setItem("updateCount", event.target.value);
    };
    return (
      <div
        style={{
          marginLeft: 10,
          marginTop: 320,
          ...theme.flexRows,
          justifyContent: "left",
          alignItems: "center",
          alignContent: "top",
          gap: 20,
          width: "100vw",
          border: "4px solid purple",
          display: user ? "flex" : "none",
          flexWrap: "wrap",
        }}
      >
        <div>
          <Button onClick={sendBatch}>send batch</Button>
          <Input
            type="number"
            value={updateCount}
            onChange={(event) => doUpdateCount(event)}
            style={{ width: 80 }}
          />
        </div>
        <TextareaAutosize
          style={{ ...theme.typography.h4 }}
          placeholder="DANCE NOTE"
          onChange={handleInfo}
          value={info}
        ></TextareaAutosize>
        <font style={{ ...theme.typography.h5 }}>Dance Day:</font>
        <Input
          variant="outlined"
          style={{
            ...theme.typography.h4,
            backgroundColor: "#fff",
          }}
          value={day}
          onChange={handleDay}
          placeholder="Month Day"
        />
        <Input
          variant="outlined"
          style={{
            ...theme.typography.h4,
            backgroundColor: "#fff",
          }}
          value={template}
          onChange={handleTempate}
          placeholder="template"
        />
        <Button onClick={() => updateDay_Msg(info, day, template)}>
          update msg
        </Button>
      </div>
    );
  };
  return (
    <Site title="Unsubscribe">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 20,
          marginLeft: 20,
          position: "relative",
        }}
      >
        <Controls />

        <CRMList />
      </div>
    </Site>
  );
};

export default Crm;
