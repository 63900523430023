export const SundayListing = {
  name: "Contra Dance",
  body: "Dance @ 7:30 PM",
  startTime: "",
  endTime: "",
};

export const SundayTemplate = {
  __typename: "EventEntity",
  id: "46",
  attributes: {
    __typename: "Event",
    createdAt: "2022-04-25T14:09:40.496Z",
    name: "Contra Dances",
    // image: {
    //   __typename: "UploadFileEntityResponse",
    //   data: {
    //     __typename: "UploadFileEntity",
    //     id: Math.random(),
    //     attributes: {
    //       __typename: "UploadFile",
    //       createdAt: "2022-04-25T14:09:40.752Z",
    //       name: "IMG_0138.JPG",
    //       formats: {
    //         thumbnail: {
    //           name: "thumbnail_IMG_0138.JPG",
    //           hash: "thumbnail_IMG_0138_537fbfd2dd",
    //           ext: ".JPG",
    //           mime: "image/jpeg",
    //           path: null,
    //           width: 146,
    //           height: 162,
    //           size: 13.56,
    //           url: "https://stage.uglytree.cafe/grace_image.png",
    //         },
    //         large: {
    //           name: "large_IMG_0138.JPG",
    //           hash: "large_IMG_0138_537fbfd2dd",
    //           ext: ".JPG",
    //           mime: "image/jpeg",
    //           path: null,
    //           width: 1000,
    //           height: 563,
    //           size: 245.16,
    //           url: "/uploads/large_IMG_0138_537fbfd2dd.JPG",
    //         },
    //         medium: {
    //           name: "medium_IMG_0138.JPG",
    //           hash: "medium_IMG_0138_537fbfd2dd",
    //           ext: ".JPG",
    //           mime: "image/jpeg",
    //           path: null,
    //           width: 750,
    //           height: 422,
    //           size: 132.05,
    //           url: "/uploads/medium_IMG_0138_537fbfd2dd.JPG",
    //         },
    //         small: {
    //           name: "small_IMG_0138.JPG",
    //           hash: "small_IMG_0138_537fbfd2dd",
    //           ext: ".JPG",
    //           mime: "image/jpeg",
    //           path: null,
    //           width: 500,
    //           height: 281,
    //           size: 58.35,
    //           url: "/uploads/small_IMG_0138_537fbfd2dd.JPG",
    //         },
    //       },
    //     },
    //   },
    // },
    startTime: "2022-04-26T16:00:00.000Z",
    body: "Contra Dance at 7:30 PM ",
    lectionary: {},
  },
};

export const Modes = {
  fill: () => {},
};

export const appTheme = {
  debug: {
    display: "none",
    fontWeight: 800,
  },
  form: {
    padding: 10,
    width: "10%",
  },
  userCard: {
    opacity: ".9",
    margin: 3,
    padding: 3,
    width: "50%",
  },
  badge: {
    whiteSpace: "nowrap",
    maxWidth: 300,
    fontWeight: 600,
    borderRadius: 3,
    padding: 2,
    margin: 3,
    textTransform: "lowercase",
  },
  submitButton: {
    border: "1px solid grey",
    padding: 3,
    fontWeight: 600,
    margin: 3,
    whiteSpace: "nowrap",
    width: 50,
    height: 60,
    borderRadius: 500,
  },
  loadMoreButton: {
    height: "60px",
    borderRadius: 3,
    border: 0,
    margin: "2 3 3 2",
    padding: "0 0 0 0",
  },
  flexRows: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  errorMessage: {
    position: "fixed",
    left: 5,
    top: "140px",
    width: "50%",
  },
  loading: {
    width: "100%",
    zIndex: 2000,
    "& > * + *": {
      marginLeft: 2,
    },
  },
  loud: {
    fontWeight: 800,
  },
  siteLink: {
    borderRadius: 5,
    padding: 3,
    whiteSpace: "nowrap",
    maxHeight: 60,
    textDecoration: "none",
  },
};

export const ColorModes = [
  {
    mode: "monochrome",
    count: "5",
    colors: [
      {
        hex: { value: "#160403", clean: "160403" },
        rgb: {
          fraction: {
            r: 0.08627450980392157,
            g: 0.01568627450980392,
            b: 0.011764705882352941,
          },
          r: 22,
          g: 4,
          b: 3,
          value: "rgb(22, 4, 3)",
        },
        hsl: {
          fraction: {
            h: 0.00877192982456132,
            s: 0.76,
            l: 0.049019607843137254,
          },
          h: 3,
          s: 76,
          l: 5,
          value: "hsl(3, 76%, 5%)",
        },
        hsv: {
          fraction: {
            h: 0.00877192982456132,
            s: 0.8636363636363636,
            v: 0.08627450980392157,
          },
          value: "hsv(3, 86%, 9%)",
          h: 3,
          s: 86,
          v: 9,
        },
        name: {
          value: "Creole",
          closest_named_hex: "#1E0F04",
          exact_match_name: false,
          distance: 694,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8181818181818189,
            y: 0.8636363636363642,
            k: 0.9137254901960784,
          },
          value: "cmyk(0, 82, 86, 91)",
          c: 0,
          m: 82,
          y: 86,
          k: 91,
        },
        XYZ: {
          fraction: {
            X: 0.04331254901960784,
            Y: 0.030410196078431373,
            Z: 0.014717254901960785,
          },
          value: "XYZ(4, 3, 1)",
          X: 4,
          Y: 3,
          Z: 1,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=160403",
          named: "https://www.thecolorapi.com/id?format=svg&hex=160403",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=160403" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#430C09", clean: "430C09" },
        rgb: {
          fraction: {
            r: 0.2627450980392157,
            g: 0.047058823529411764,
            b: 0.03529411764705882,
          },
          r: 67,
          g: 12,
          b: 9,
          value: "rgb(67, 12, 9)",
        },
        hsl: {
          fraction: {
            h: 0.008620689655172376,
            s: 0.7631578947368421,
            l: 0.14901960784313725,
          },
          h: 3,
          s: 76,
          l: 15,
          value: "hsl(3, 76%, 15%)",
        },
        hsv: {
          fraction: {
            h: 0.008620689655172376,
            s: 0.8656716417910448,
            v: 0.2627450980392157,
          },
          value: "hsv(3, 87%, 26%)",
          h: 3,
          s: 87,
          v: 26,
        },
        name: {
          value: "Aubergine",
          closest_named_hex: "#3B0910",
          exact_match_name: false,
          distance: 350,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8208955223880594,
            y: 0.8656716417910448,
            k: 0.7372549019607844,
          },
          value: "cmyk(0, 82, 87, 74)",
          c: 0,
          m: 82,
          y: 87,
          k: 74,
        },
        XYZ: {
          fraction: {
            X: 0.13155490196078434,
            Y: 0.09206431372549019,
            Z: 0.04422745098039216,
          },
          value: "XYZ(13, 9, 4)",
          X: 13,
          Y: 9,
          Z: 4,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=430C09",
          named: "https://www.thecolorapi.com/id?format=svg&hex=430C09",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=430C09" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#71130E", clean: "71130E" },
        rgb: {
          fraction: {
            r: 0.44313725490196076,
            g: 0.07450980392156863,
            b: 0.054901960784313725,
          },
          r: 113,
          g: 19,
          b: 14,
          value: "rgb(113, 19, 14)",
        },
        hsl: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.7795275590551181,
            l: 0.24901960784313726,
          },
          h: 3,
          s: 78,
          l: 25,
          value: "hsl(3, 78%, 25%)",
        },
        hsv: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.8761061946902654,
            v: 0.44313725490196076,
          },
          value: "hsv(3, 88%, 44%)",
          h: 3,
          s: 88,
          v: 44,
        },
        name: {
          value: "Dark Tan",
          closest_named_hex: "#661010",
          exact_match_name: false,
          distance: 512,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8318584070796461,
            y: 0.8761061946902654,
            k: 0.5568627450980392,
          },
          value: "cmyk(0, 83, 88, 56)",
          c: 0,
          m: 83,
          y: 88,
          k: 56,
        },
        XYZ: {
          fraction: {
            X: 0.21930431372549017,
            Y: 0.15146431372549019,
            Z: 0.06961843137254903,
          },
          value: "XYZ(22, 15, 7)",
          X: 22,
          Y: 15,
          Z: 7,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
          named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=71130E" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#9F1A13", clean: "9F1A13" },
        rgb: {
          fraction: {
            r: 0.6235294117647059,
            g: 0.10196078431372549,
            b: 0.07450980392156863,
          },
          r: 159,
          g: 26,
          b: 19,
          value: "rgb(159, 26, 19)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7865168539325844,
            l: 0.34901960784313724,
          },
          h: 3,
          s: 79,
          l: 35,
          value: "hsl(3, 79%, 35%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8805031446540881,
            v: 0.6235294117647059,
          },
          value: "hsv(3, 88%, 62%)",
          h: 3,
          s: 88,
          v: 62,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 100,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8364779874213837,
            y: 0.8805031446540881,
            k: 0.3764705882352941,
          },
          value: "cmyk(0, 84, 88, 38)",
          c: 0,
          m: 84,
          y: 88,
          k: 38,
        },
        XYZ: {
          fraction: {
            X: 0.30705372549019605,
            Y: 0.21086431372549022,
            Z: 0.09500941176470588,
          },
          value: "XYZ(31, 21, 10)",
          X: 31,
          Y: 21,
          Z: 10,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9F1A13",
          named: "https://www.thecolorapi.com/id?format=svg&hex=9F1A13",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=9F1A13" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#CE2017", clean: "CE2017" },
        rgb: {
          fraction: {
            r: 0.807843137254902,
            g: 0.12549019607843137,
            b: 0.09019607843137255,
          },
          r: 206,
          g: 32,
          b: 23,
          value: "rgb(206, 32, 23)",
        },
        hsl: {
          fraction: {
            h: 0.00819672131147553,
            s: 0.7991266375545851,
            l: 0.44901960784313727,
          },
          h: 3,
          s: 80,
          l: 45,
          value: "hsl(3, 80%, 45%)",
        },
        hsv: {
          fraction: {
            h: 0.00819672131147553,
            s: 0.8883495145631067,
            v: 0.807843137254902,
          },
          value: "hsv(3, 89%, 81%)",
          h: 3,
          s: 89,
          v: 81,
        },
        name: {
          value: "Thunderbird",
          closest_named_hex: "#C02B18",
          exact_match_name: false,
          distance: 448,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8446601941747572,
            y: 0.8883495145631067,
            k: 0.19215686274509802,
          },
          value: "cmyk(0, 84, 89, 19)",
          c: 0,
          m: 84,
          y: 89,
          k: 19,
        },
        XYZ: {
          fraction: {
            X: 0.3943101960784314,
            Y: 0.26801019607843135,
            Z: 0.11628117647058824,
          },
          value: "XYZ(39, 27, 12)",
          X: 39,
          Y: 27,
          Z: 12,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=CE2017",
          named: "https://www.thecolorapi.com/id?format=svg&hex=CE2017",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=CE2017" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=monochrome&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "monochrome-dark",
    count: "5",
    colors: [
      {
        hex: { value: "#160403", clean: "160403" },
        rgb: {
          fraction: {
            r: 0.08627450980392157,
            g: 0.01568627450980392,
            b: 0.011764705882352941,
          },
          r: 22,
          g: 4,
          b: 3,
          value: "rgb(22, 4, 3)",
        },
        hsl: {
          fraction: {
            h: 0.00877192982456132,
            s: 0.76,
            l: 0.049019607843137254,
          },
          h: 3,
          s: 76,
          l: 5,
          value: "hsl(3, 76%, 5%)",
        },
        hsv: {
          fraction: {
            h: 0.00877192982456132,
            s: 0.8636363636363636,
            v: 0.08627450980392157,
          },
          value: "hsv(3, 86%, 9%)",
          h: 3,
          s: 86,
          v: 9,
        },
        name: {
          value: "Creole",
          closest_named_hex: "#1E0F04",
          exact_match_name: false,
          distance: 694,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8181818181818189,
            y: 0.8636363636363642,
            k: 0.9137254901960784,
          },
          value: "cmyk(0, 82, 86, 91)",
          c: 0,
          m: 82,
          y: 86,
          k: 91,
        },
        XYZ: {
          fraction: {
            X: 0.04331254901960784,
            Y: 0.030410196078431373,
            Z: 0.014717254901960785,
          },
          value: "XYZ(4, 3, 1)",
          X: 4,
          Y: 3,
          Z: 1,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=160403",
          named: "https://www.thecolorapi.com/id?format=svg&hex=160403",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=160403" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#430C09", clean: "430C09" },
        rgb: {
          fraction: {
            r: 0.2627450980392157,
            g: 0.047058823529411764,
            b: 0.03529411764705882,
          },
          r: 67,
          g: 12,
          b: 9,
          value: "rgb(67, 12, 9)",
        },
        hsl: {
          fraction: {
            h: 0.008620689655172376,
            s: 0.7631578947368421,
            l: 0.14901960784313725,
          },
          h: 3,
          s: 76,
          l: 15,
          value: "hsl(3, 76%, 15%)",
        },
        hsv: {
          fraction: {
            h: 0.008620689655172376,
            s: 0.8656716417910448,
            v: 0.2627450980392157,
          },
          value: "hsv(3, 87%, 26%)",
          h: 3,
          s: 87,
          v: 26,
        },
        name: {
          value: "Aubergine",
          closest_named_hex: "#3B0910",
          exact_match_name: false,
          distance: 350,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8208955223880594,
            y: 0.8656716417910448,
            k: 0.7372549019607844,
          },
          value: "cmyk(0, 82, 87, 74)",
          c: 0,
          m: 82,
          y: 87,
          k: 74,
        },
        XYZ: {
          fraction: {
            X: 0.13155490196078434,
            Y: 0.09206431372549019,
            Z: 0.04422745098039216,
          },
          value: "XYZ(13, 9, 4)",
          X: 13,
          Y: 9,
          Z: 4,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=430C09",
          named: "https://www.thecolorapi.com/id?format=svg&hex=430C09",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=430C09" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#71130E", clean: "71130E" },
        rgb: {
          fraction: {
            r: 0.44313725490196076,
            g: 0.07450980392156863,
            b: 0.054901960784313725,
          },
          r: 113,
          g: 19,
          b: 14,
          value: "rgb(113, 19, 14)",
        },
        hsl: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.7795275590551181,
            l: 0.24901960784313726,
          },
          h: 3,
          s: 78,
          l: 25,
          value: "hsl(3, 78%, 25%)",
        },
        hsv: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.8761061946902654,
            v: 0.44313725490196076,
          },
          value: "hsv(3, 88%, 44%)",
          h: 3,
          s: 88,
          v: 44,
        },
        name: {
          value: "Dark Tan",
          closest_named_hex: "#661010",
          exact_match_name: false,
          distance: 512,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8318584070796461,
            y: 0.8761061946902654,
            k: 0.5568627450980392,
          },
          value: "cmyk(0, 83, 88, 56)",
          c: 0,
          m: 83,
          y: 88,
          k: 56,
        },
        XYZ: {
          fraction: {
            X: 0.21930431372549017,
            Y: 0.15146431372549019,
            Z: 0.06961843137254903,
          },
          value: "XYZ(22, 15, 7)",
          X: 22,
          Y: 15,
          Z: 7,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
          named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=71130E" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#595959", clean: "595959" },
        rgb: {
          fraction: {
            r: 0.34901960784313724,
            g: 0.34901960784313724,
            b: 0.34901960784313724,
          },
          r: 89,
          g: 89,
          b: 89,
          value: "rgb(89, 89, 89)",
        },
        hsl: {
          fraction: { h: 0, s: 0, l: 0.34901960784313724 },
          h: 0,
          s: 0,
          l: 35,
          value: "hsl(0, 0%, 35%)",
        },
        hsv: {
          fraction: { h: 0, s: 0, v: 0.34901960784313724 },
          value: "hsv(0, 0%, 35%)",
          h: 0,
          s: 0,
          v: 35,
        },
        name: {
          value: "Granite Gray",
          closest_named_hex: "#676767",
          exact_match_name: false,
          distance: 980,
        },
        cmyk: {
          fraction: { c: 0, m: 0, y: 0, k: 0.6509803921568628 },
          value: "cmyk(0, 0, 0, 65)",
          c: 0,
          m: 0,
          y: 0,
          k: 65,
        },
        XYZ: {
          fraction: {
            X: 0.3317431372549019,
            Y: 0.34901960784313724,
            Z: 0.3800823529411765,
          },
          value: "XYZ(33, 35, 38)",
          X: 33,
          Y: 35,
          Z: 38,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=595959",
          named: "https://www.thecolorapi.com/id?format=svg&hex=595959",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=595959" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#727272", clean: "727272" },
        rgb: {
          fraction: {
            r: 0.4470588235294118,
            g: 0.4470588235294118,
            b: 0.4470588235294118,
          },
          r: 114,
          g: 114,
          b: 114,
          value: "rgb(114, 114, 114)",
        },
        hsl: {
          fraction: { h: 0, s: 0, l: 0.4470588235294118 },
          h: 0,
          s: 0,
          l: 45,
          value: "hsl(0, 0%, 45%)",
        },
        hsv: {
          fraction: { h: 0, s: 0, v: 0.4470588235294118 },
          value: "hsv(0, 0%, 45%)",
          h: 0,
          s: 0,
          v: 45,
        },
        name: {
          value: "Sonic Silver",
          closest_named_hex: "#757575",
          exact_match_name: false,
          distance: 45,
        },
        cmyk: {
          fraction: { c: 0, m: 0, y: 0, k: 0.5529411764705883 },
          value: "cmyk(0, 0, 0, 55)",
          c: 0,
          m: 0,
          y: 0,
          k: 55,
        },
        XYZ: {
          fraction: {
            X: 0.4249294117647059,
            Y: 0.44705882352941173,
            Z: 0.4868470588235294,
          },
          value: "XYZ(42, 45, 49)",
          X: 42,
          Y: 45,
          Z: 49,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=727272",
          named: "https://www.thecolorapi.com/id?format=svg&hex=727272",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=727272" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome-dark&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome-dark&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=monochrome-dark&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "monochrome-light",
    count: "5",
    colors: [
      {
        hex: { value: "#0C0C0C", clean: "0C0C0C" },
        rgb: {
          fraction: {
            r: 0.047058823529411764,
            g: 0.047058823529411764,
            b: 0.047058823529411764,
          },
          r: 12,
          g: 12,
          b: 12,
          value: "rgb(12, 12, 12)",
        },
        hsl: {
          fraction: { h: 0, s: 0, l: 0.047058823529411764 },
          h: 0,
          s: 0,
          l: 5,
          value: "hsl(0, 0%, 5%)",
        },
        hsv: {
          fraction: { h: 0, s: 0, v: 0.047058823529411764 },
          value: "hsv(0, 0%, 5%)",
          h: 0,
          s: 0,
          v: 5,
        },
        name: {
          value: "Cod Gray",
          closest_named_hex: "#0B0B0B",
          exact_match_name: false,
          distance: 5,
        },
        cmyk: {
          fraction: { c: 0, m: 0, y: 0, k: 0.9529411764705882 },
          value: "cmyk(0, 0, 0, 95)",
          c: 0,
          m: 0,
          y: 0,
          k: 95,
        },
        XYZ: {
          fraction: {
            X: 0.044729411764705886,
            Y: 0.047058823529411764,
            Z: 0.051247058823529416,
          },
          value: "XYZ(4, 5, 5)",
          X: 4,
          Y: 5,
          Z: 5,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=0C0C0C",
          named: "https://www.thecolorapi.com/id?format=svg&hex=0C0C0C",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=0C0C0C" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#262626", clean: "262626" },
        rgb: {
          fraction: {
            r: 0.14901960784313725,
            g: 0.14901960784313725,
            b: 0.14901960784313725,
          },
          r: 38,
          g: 38,
          b: 38,
          value: "rgb(38, 38, 38)",
        },
        hsl: {
          fraction: { h: 0, s: 0, l: 0.14901960784313725 },
          h: 0,
          s: 0,
          l: 15,
          value: "hsl(0, 0%, 15%)",
        },
        hsv: {
          fraction: { h: 0, s: 0, v: 0.14901960784313725 },
          value: "hsv(0, 0%, 15%)",
          h: 0,
          s: 0,
          v: 15,
        },
        name: {
          value: "Eerie Black",
          closest_named_hex: "#1B1B1B",
          exact_match_name: false,
          distance: 605,
        },
        cmyk: {
          fraction: { c: 0, m: 0, y: 0, k: 0.8509803921568627 },
          value: "cmyk(0, 0, 0, 85)",
          c: 0,
          m: 0,
          y: 0,
          k: 85,
        },
        XYZ: {
          fraction: {
            X: 0.14164313725490196,
            Y: 0.14901960784313723,
            Z: 0.16228235294117646,
          },
          value: "XYZ(14, 15, 16)",
          X: 14,
          Y: 15,
          Z: 16,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=262626",
          named: "https://www.thecolorapi.com/id?format=svg&hex=262626",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=262626" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#71130E", clean: "71130E" },
        rgb: {
          fraction: {
            r: 0.44313725490196076,
            g: 0.07450980392156863,
            b: 0.054901960784313725,
          },
          r: 113,
          g: 19,
          b: 14,
          value: "rgb(113, 19, 14)",
        },
        hsl: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.7795275590551181,
            l: 0.24901960784313726,
          },
          h: 3,
          s: 78,
          l: 25,
          value: "hsl(3, 78%, 25%)",
        },
        hsv: {
          fraction: {
            h: 0.008417508417508324,
            s: 0.8761061946902654,
            v: 0.44313725490196076,
          },
          value: "hsv(3, 88%, 44%)",
          h: 3,
          s: 88,
          v: 44,
        },
        name: {
          value: "Dark Tan",
          closest_named_hex: "#661010",
          exact_match_name: false,
          distance: 512,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8318584070796461,
            y: 0.8761061946902654,
            k: 0.5568627450980392,
          },
          value: "cmyk(0, 83, 88, 56)",
          c: 0,
          m: 83,
          y: 88,
          k: 56,
        },
        XYZ: {
          fraction: {
            X: 0.21930431372549017,
            Y: 0.15146431372549019,
            Z: 0.06961843137254903,
          },
          value: "XYZ(22, 15, 7)",
          X: 22,
          Y: 15,
          Z: 7,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
          named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=71130E" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#9F1A13", clean: "9F1A13" },
        rgb: {
          fraction: {
            r: 0.6235294117647059,
            g: 0.10196078431372549,
            b: 0.07450980392156863,
          },
          r: 159,
          g: 26,
          b: 19,
          value: "rgb(159, 26, 19)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7865168539325844,
            l: 0.34901960784313724,
          },
          h: 3,
          s: 79,
          l: 35,
          value: "hsl(3, 79%, 35%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8805031446540881,
            v: 0.6235294117647059,
          },
          value: "hsv(3, 88%, 62%)",
          h: 3,
          s: 88,
          v: 62,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 100,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8364779874213837,
            y: 0.8805031446540881,
            k: 0.3764705882352941,
          },
          value: "cmyk(0, 84, 88, 38)",
          c: 0,
          m: 84,
          y: 88,
          k: 38,
        },
        XYZ: {
          fraction: {
            X: 0.30705372549019605,
            Y: 0.21086431372549022,
            Z: 0.09500941176470588,
          },
          value: "XYZ(31, 21, 10)",
          X: 31,
          Y: 21,
          Z: 10,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9F1A13",
          named: "https://www.thecolorapi.com/id?format=svg&hex=9F1A13",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=9F1A13" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#CE2017", clean: "CE2017" },
        rgb: {
          fraction: {
            r: 0.807843137254902,
            g: 0.12549019607843137,
            b: 0.09019607843137255,
          },
          r: 206,
          g: 32,
          b: 23,
          value: "rgb(206, 32, 23)",
        },
        hsl: {
          fraction: {
            h: 0.00819672131147553,
            s: 0.7991266375545851,
            l: 0.44901960784313727,
          },
          h: 3,
          s: 80,
          l: 45,
          value: "hsl(3, 80%, 45%)",
        },
        hsv: {
          fraction: {
            h: 0.00819672131147553,
            s: 0.8883495145631067,
            v: 0.807843137254902,
          },
          value: "hsv(3, 89%, 81%)",
          h: 3,
          s: 89,
          v: 81,
        },
        name: {
          value: "Thunderbird",
          closest_named_hex: "#C02B18",
          exact_match_name: false,
          distance: 448,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8446601941747572,
            y: 0.8883495145631067,
            k: 0.19215686274509802,
          },
          value: "cmyk(0, 84, 89, 19)",
          c: 0,
          m: 84,
          y: 89,
          k: 19,
        },
        XYZ: {
          fraction: {
            X: 0.3943101960784314,
            Y: 0.26801019607843135,
            Z: 0.11628117647058824,
          },
          value: "XYZ(39, 27, 12)",
          X: 39,
          Y: 27,
          Z: 12,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=CE2017",
          named: "https://www.thecolorapi.com/id?format=svg&hex=CE2017",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=CE2017" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome-light&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome-light&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=monochrome-light&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "analogic",
    count: "5",
    colors: [
      {
        hex: { value: "#7B1611", clean: "7B1611" },
        rgb: {
          fraction: {
            r: 0.4823529411764706,
            g: 0.08627450980392157,
            b: 0.06666666666666667,
          },
          r: 123,
          g: 22,
          b: 17,
          value: "rgb(123, 22, 17)",
        },
        hsl: {
          fraction: {
            h: 0.007861635220125729,
            s: 0.7571428571428571,
            l: 0.27450980392156865,
          },
          h: 3,
          s: 76,
          l: 27,
          value: "hsl(3, 76%, 27%)",
        },
        hsv: {
          fraction: {
            h: 0.007861635220125729,
            s: 0.8617886178861789,
            v: 0.4823529411764706,
          },
          value: "hsv(3, 86%, 48%)",
          h: 3,
          s: 86,
          v: 48,
        },
        name: {
          value: "Pueblo",
          closest_named_hex: "#7D2C14",
          exact_match_name: false,
          distance: 765,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8211382113821137,
            y: 0.8617886178861789,
            k: 0.5176470588235293,
          },
          value: "cmyk(0, 82, 86, 52)",
          c: 0,
          m: 82,
          y: 86,
          k: 52,
        },
        XYZ: {
          fraction: {
            X: 0.24180745098039216,
            Y: 0.16906509803921568,
            Z: 0.08296,
          },
          value: "XYZ(24, 17, 8)",
          X: 24,
          Y: 17,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=7B1611",
          named: "https://www.thecolorapi.com/id?format=svg&hex=7B1611",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=7B1611" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#803711", clean: "803711" },
        rgb: {
          fraction: {
            r: 0.5019607843137255,
            g: 0.21568627450980393,
            b: 0.06666666666666667,
          },
          r: 128,
          g: 55,
          b: 17,
          value: "rgb(128, 55, 17)",
        },
        hsl: {
          fraction: {
            h: 0.0570570570570571,
            s: 0.7655172413793104,
            l: 0.28431372549019607,
          },
          h: 21,
          s: 77,
          l: 28,
          value: "hsl(21, 77%, 28%)",
        },
        hsv: {
          fraction: {
            h: 0.0570570570570571,
            s: 0.8671875,
            v: 0.5019607843137255,
          },
          value: "hsv(21, 87%, 50%)",
          h: 21,
          s: 87,
          v: 50,
        },
        name: {
          value: "Copper Canyon",
          closest_named_hex: "#7E3A15",
          exact_match_name: false,
          distance: 369,
        },
        cmyk: {
          fraction: { c: 0, m: 0.5703125, y: 0.8671875, k: 0.4980392156862745 },
          value: "cmyk(0, 57, 87, 50)",
          c: 0,
          m: 57,
          y: 87,
          k: 50,
        },
        XYZ: {
          fraction: {
            X: 0.2961713725490196,
            Y: 0.2657890196078431,
            Z: 0.0987643137254902,
          },
          value: "XYZ(30, 27, 10)",
          X: 30,
          Y: 27,
          Z: 10,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=803711",
          named: "https://www.thecolorapi.com/id?format=svg&hex=803711",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=803711" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#855C10", clean: "855C10" },
        rgb: {
          fraction: {
            r: 0.5215686274509804,
            g: 0.3607843137254902,
            b: 0.06274509803921569,
          },
          r: 133,
          g: 92,
          b: 16,
          value: "rgb(133, 92, 16)",
        },
        hsl: {
          fraction: {
            h: 0.10826210826210825,
            s: 0.785234899328859,
            l: 0.29215686274509806,
          },
          h: 39,
          s: 79,
          l: 29,
          value: "hsl(39, 79%, 29%)",
        },
        hsv: {
          fraction: {
            h: 0.10826210826210825,
            s: 0.8796992481203008,
            v: 0.5215686274509804,
          },
          value: "hsv(39, 88%, 52%)",
          h: 39,
          s: 88,
          v: 52,
        },
        name: {
          value: "Korma",
          closest_named_hex: "#8F4B0E",
          exact_match_name: false,
          distance: 685,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.30827067669172925,
            y: 0.8796992481203008,
            k: 0.4784313725490196,
          },
          value: "cmyk(0, 31, 88, 48)",
          c: 0,
          m: 31,
          y: 88,
          k: 48,
        },
        XYZ: {
          fraction: {
            X: 0.355436862745098,
            Y: 0.3734486274509804,
            Z: 0.11271098039215686,
          },
          value: "XYZ(36, 37, 11)",
          X: 36,
          Y: 37,
          Z: 11,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=855C10",
          named: "https://www.thecolorapi.com/id?format=svg&hex=855C10",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=855C10" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#8A8410", clean: "8A8410" },
        rgb: {
          fraction: {
            r: 0.5411764705882353,
            g: 0.5176470588235295,
            b: 0.06274509803921569,
          },
          r: 138,
          g: 132,
          b: 16,
          value: "rgb(138, 132, 16)",
        },
        hsl: {
          fraction: {
            h: 0.1584699453551912,
            s: 0.7922077922077922,
            l: 0.30196078431372547,
          },
          h: 57,
          s: 79,
          l: 30,
          value: "hsl(57, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.1584699453551912,
            s: 0.8840579710144928,
            v: 0.5411764705882353,
          },
          value: "hsv(57, 88%, 54%)",
          h: 57,
          s: 88,
          v: 54,
        },
        name: {
          value: "Corn Harvest",
          closest_named_hex: "#8B6B0B",
          exact_match_name: false,
          distance: 1281,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.043478260869565064,
            y: 0.8840579710144928,
            k: 0.45882352941176474,
          },
          value: "cmyk(0, 4, 88, 46)",
          c: 0,
          m: 4,
          y: 88,
          k: 46,
        },
        XYZ: {
          fraction: {
            X: 0.4196172549019608,
            Y: 0.4898054901960784,
            Z: 0.13178745098039216,
          },
          value: "XYZ(42, 49, 13)",
          X: 42,
          Y: 49,
          Z: 13,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8A8410",
          named: "https://www.thecolorapi.com/id?format=svg&hex=8A8410",
        },
        contrast: { value: "#000000" },
        _links: { self: { href: "/id?hex=8A8410" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#709010", clean: "709010" },
        rgb: {
          fraction: {
            r: 0.4392156862745098,
            g: 0.5647058823529412,
            b: 0.06274509803921569,
          },
          r: 112,
          g: 144,
          b: 16,
          value: "rgb(112, 144, 16)",
        },
        hsl: {
          fraction: { h: 0.20833333333333337, s: 0.8, l: 0.3137254901960784 },
          h: 75,
          s: 80,
          l: 31,
          value: "hsl(75, 80%, 31%)",
        },
        hsv: {
          fraction: {
            h: 0.20833333333333337,
            s: 0.8888888888888888,
            v: 0.5647058823529412,
          },
          value: "hsv(75, 89%, 56%)",
          h: 75,
          s: 89,
          v: 56,
        },
        name: {
          value: "Christi",
          closest_named_hex: "#67A712",
          exact_match_name: false,
          distance: 1002,
        },
        cmyk: {
          fraction: {
            c: 0.2222222222222222,
            m: 0,
            y: 0.8888888888888888,
            k: 0.43529411764705883,
          },
          value: "cmyk(22, 0, 89, 44)",
          c: 22,
          m: 0,
          y: 89,
          k: 44,
        },
        XYZ: {
          fraction: {
            X: 0.394396862745098,
            Y: 0.5017850980392157,
            Z: 0.13542901960784315,
          },
          value: "XYZ(39, 50, 14)",
          X: 39,
          Y: 50,
          Z: 14,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=709010",
          named: "https://www.thecolorapi.com/id?format=svg&hex=709010",
        },
        contrast: { value: "#000000" },
        _links: { self: { href: "/id?hex=709010" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=analogic&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=analogic&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=analogic&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "complement",
    count: "5",
    colors: [
      {
        hex: { value: "#116367", clean: "116367" },
        rgb: {
          fraction: {
            r: 0.06666666666666667,
            g: 0.38823529411764707,
            b: 0.403921568627451,
          },
          r: 17,
          g: 99,
          b: 103,
          value: "rgb(17, 99, 103)",
        },
        hsl: {
          fraction: {
            h: 0.507751937984496,
            s: 0.7166666666666667,
            l: 0.23529411764705882,
          },
          h: 183,
          s: 72,
          l: 24,
          value: "hsl(183, 72%, 24%)",
        },
        hsv: {
          fraction: {
            h: 0.507751937984496,
            s: 0.8349514563106797,
            v: 0.403921568627451,
          },
          value: "hsv(183, 83%, 40%)",
          h: 183,
          s: 83,
          v: 40,
        },
        name: {
          value: "Genoa",
          closest_named_hex: "#15736B",
          exact_match_name: false,
          distance: 560,
        },
        cmyk: {
          fraction: {
            c: 0.8349514563106797,
            m: 0.038834951456310815,
            y: 0,
            k: 0.596078431372549,
          },
          value: "cmyk(83, 4, 0, 60)",
          c: 83,
          m: 4,
          y: 0,
          k: 60,
        },
        XYZ: {
          fraction: {
            X: 0.23923411764705882,
            Y: 0.3210023529411764,
            Z: 0.43149176470588235,
          },
          value: "XYZ(24, 32, 43)",
          X: 24,
          Y: 32,
          Z: 43,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116367",
          named: "https://www.thecolorapi.com/id?format=svg&hex=116367",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=116367" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#116F74", clean: "116F74" },
        rgb: {
          fraction: {
            r: 0.06666666666666667,
            g: 0.43529411764705883,
            b: 0.4549019607843137,
          },
          r: 17,
          g: 111,
          b: 116,
          value: "rgb(17, 111, 116)",
        },
        hsl: {
          fraction: {
            h: 0.5084175084175084,
            s: 0.7443609022556391,
            l: 0.2607843137254902,
          },
          h: 183,
          s: 74,
          l: 26,
          value: "hsl(183, 74%, 26%)",
        },
        hsv: {
          fraction: {
            h: 0.5084175084175084,
            s: 0.853448275862069,
            v: 0.4549019607843137,
          },
          value: "hsv(183, 85%, 45%)",
          h: 183,
          s: 85,
          v: 45,
        },
        name: {
          value: "Genoa",
          closest_named_hex: "#15736B",
          exact_match_name: false,
          distance: 531,
        },
        cmyk: {
          fraction: {
            c: 0.853448275862069,
            m: 0.04310344827586192,
            y: 0,
            k: 0.5450980392156863,
          },
          value: "cmyk(85, 4, 0, 55)",
          c: 85,
          m: 4,
          y: 0,
          k: 55,
        },
        XYZ: {
          fraction: {
            X: 0.2652643137254902,
            Y: 0.35833960784313723,
            Z: 0.48555803921568624,
          },
          value: "XYZ(27, 36, 49)",
          X: 27,
          Y: 36,
          Z: 49,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116F74",
          named: "https://www.thecolorapi.com/id?format=svg&hex=116F74",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=116F74" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#811711", clean: "811711" },
        rgb: {
          fraction: {
            r: 0.5058823529411764,
            g: 0.09019607843137255,
            b: 0.06666666666666667,
          },
          r: 129,
          g: 23,
          b: 17,
          value: "rgb(129, 23, 17)",
        },
        hsl: {
          fraction: {
            h: 0.008928571428571508,
            s: 0.767123287671233,
            l: 0.28627450980392155,
          },
          h: 3,
          s: 77,
          l: 29,
          value: "hsl(3, 77%, 29%)",
        },
        hsv: {
          fraction: {
            h: 0.008928571428571508,
            s: 0.8682170542635659,
            v: 0.5058823529411764,
          },
          value: "hsv(3, 87%, 51%)",
          h: 3,
          s: 87,
          v: 51,
        },
        name: {
          value: "Pueblo",
          closest_named_hex: "#7D2C14",
          exact_match_name: false,
          distance: 808,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8217054263565892,
            y: 0.8682170542635659,
            k: 0.49411764705882355,
          },
          value: "cmyk(0, 82, 87, 49)",
          c: 0,
          m: 82,
          y: 87,
          k: 49,
        },
        XYZ: {
          fraction: {
            X: 0.2529133333333333,
            Y: 0.1768721568627451,
            Z: 0.08388156862745098,
          },
          value: "XYZ(25, 18, 8)",
          X: 25,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=811711",
          named: "https://www.thecolorapi.com/id?format=svg&hex=811711",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=811711" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#8E1710", clean: "8E1710" },
        rgb: {
          fraction: {
            r: 0.5568627450980392,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 142,
          g: 23,
          b: 16,
          value: "rgb(142, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.7974683544303798,
            l: 0.30980392156862746,
          },
          h: 3,
          s: 80,
          l: 31,
          value: "hsl(3, 80%, 31%)",
        },
        hsv: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.8873239436619719,
            v: 0.5568627450980392,
          },
          value: "hsv(3, 89%, 56%)",
          h: 3,
          s: 89,
          v: 56,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 261,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8380281690140845,
            y: 0.8873239436619719,
            k: 0.44313725490196076,
          },
          value: "cmyk(0, 84, 89, 44)",
          c: 0,
          m: 84,
          y: 89,
          k: 44,
        },
        XYZ: {
          fraction: {
            X: 0.2732298039215686,
            Y: 0.18742745098039215,
            Z: 0.08113803921568627,
          },
          value: "XYZ(27, 19, 8)",
          X: 27,
          Y: 19,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=8E1710" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#9B1710", clean: "9B1710" },
        rgb: {
          fraction: {
            r: 0.6078431372549019,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 155,
          g: 23,
          b: 16,
          value: "rgb(155, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.8128654970760234,
            l: 0.3352941176470588,
          },
          h: 3,
          s: 81,
          l: 34,
          value: "hsl(3, 81%, 34%)",
        },
        hsv: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.896774193548387,
            v: 0.6078431372549019,
          },
          value: "hsv(3, 90%, 61%)",
          h: 3,
          s: 90,
          v: 61,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 184,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8516129032258064,
            y: 0.896774193548387,
            k: 0.3921568627450981,
          },
          value: "cmyk(0, 85, 90, 39)",
          c: 0,
          m: 85,
          y: 90,
          k: 39,
        },
        XYZ: {
          fraction: {
            X: 0.2942541176470588,
            Y: 0.19826588235294118,
            Z: 0.08212196078431372,
          },
          value: "XYZ(29, 20, 8)",
          X: 29,
          Y: 20,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=9B1710" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=complement&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=complement&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=complement&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "analogic-complement",
    count: "5",
    colors: [
      {
        hex: { value: "#104649", clean: "104649" },
        rgb: {
          fraction: {
            r: 0.06274509803921569,
            g: 0.27450980392156865,
            b: 0.28627450980392155,
          },
          r: 16,
          g: 70,
          b: 73,
          value: "rgb(16, 70, 73)",
        },
        hsl: {
          fraction: {
            h: 0.5087719298245612,
            s: 0.6404494382022472,
            l: 0.17450980392156862,
          },
          h: 183,
          s: 64,
          l: 17,
          value: "hsl(183, 64%, 17%)",
        },
        hsv: {
          fraction: {
            h: 0.5087719298245612,
            s: 0.7808219178082192,
            v: 0.28627450980392155,
          },
          value: "hsv(183, 78%, 29%)",
          h: 183,
          s: 78,
          v: 29,
        },
        name: {
          value: "Eden",
          closest_named_hex: "#105852",
          exact_match_name: false,
          distance: 943,
        },
        cmyk: {
          fraction: {
            c: 0.7808219178082192,
            m: 0.04109589041095876,
            y: 0,
            k: 0.7137254901960784,
          },
          value: "cmyk(78, 4, 0, 71)",
          c: 78,
          m: 4,
          y: 0,
          k: 71,
        },
        XYZ: {
          fraction: {
            X: 0.17571333333333333,
            Y: 0.2303380392156863,
            Z: 0.3060364705882353,
          },
          value: "XYZ(18, 23, 31)",
          X: 18,
          Y: 23,
          Z: 31,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=104649",
          named: "https://www.thecolorapi.com/id?format=svg&hex=104649",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=104649" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#115C60", clean: "115C60" },
        rgb: {
          fraction: {
            r: 0.06666666666666667,
            g: 0.3607843137254902,
            b: 0.3764705882352941,
          },
          r: 17,
          g: 92,
          b: 96,
          value: "rgb(17, 92, 96)",
        },
        hsl: {
          fraction: {
            h: 0.5084388185654007,
            s: 0.6991150442477877,
            l: 0.22156862745098038,
          },
          h: 183,
          s: 70,
          l: 22,
          value: "hsl(183, 70%, 22%)",
        },
        hsv: {
          fraction: {
            h: 0.5084388185654007,
            s: 0.8229166666666667,
            v: 0.3764705882352941,
          },
          value: "hsv(183, 82%, 38%)",
          h: 183,
          s: 82,
          v: 38,
        },
        name: {
          value: "Eden",
          closest_named_hex: "#105852",
          exact_match_name: false,
          distance: 325,
        },
        cmyk: {
          fraction: {
            c: 0.8229166666666667,
            m: 0.04166666666666652,
            y: 0,
            k: 0.6235294117647059,
          },
          value: "cmyk(82, 4, 0, 62)",
          c: 82,
          m: 4,
          y: 0,
          k: 62,
        },
        XYZ: {
          fraction: {
            X: 0.2244627450980392,
            Y: 0.2993874509803921,
            Z: 0.40212745098039215,
          },
          value: "XYZ(22, 30, 40)",
          X: 22,
          Y: 30,
          Z: 40,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=115C60",
          named: "https://www.thecolorapi.com/id?format=svg&hex=115C60",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=115C60" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#857F10", clean: "857F10" },
        rgb: {
          fraction: {
            r: 0.5215686274509804,
            g: 0.4980392156862745,
            b: 0.06274509803921569,
          },
          r: 133,
          g: 127,
          b: 16,
          value: "rgb(133, 127, 16)",
        },
        hsl: {
          fraction: {
            h: 0.158119658119658,
            s: 0.785234899328859,
            l: 0.29215686274509806,
          },
          h: 57,
          s: 79,
          l: 29,
          value: "hsl(57, 79%, 29%)",
        },
        hsv: {
          fraction: {
            h: 0.158119658119658,
            s: 0.8796992481203008,
            v: 0.5215686274509804,
          },
          value: "hsv(57, 88%, 52%)",
          h: 57,
          s: 88,
          v: 52,
        },
        name: {
          value: "Olivetone",
          closest_named_hex: "#716E10",
          exact_match_name: false,
          distance: 1053,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.04511278195488727,
            y: 0.8796992481203008,
            k: 0.4784313725490196,
          },
          value: "cmyk(0, 5, 88, 48)",
          c: 0,
          m: 5,
          y: 88,
          k: 48,
        },
        XYZ: {
          fraction: {
            X: 0.40451921568627447,
            Y: 0.47161333333333333,
            Z: 0.12907176470588236,
          },
          value: "XYZ(40, 47, 13)",
          X: 40,
          Y: 47,
          Z: 13,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=857F10",
          named: "https://www.thecolorapi.com/id?format=svg&hex=857F10",
        },
        contrast: { value: "#000000" },
        _links: { self: { href: "/id?hex=857F10" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#598A10", clean: "598A10" },
        rgb: {
          fraction: {
            r: 0.34901960784313724,
            g: 0.5411764705882353,
            b: 0.06274509803921569,
          },
          r: 89,
          g: 138,
          b: 16,
          value: "rgb(89, 138, 16)",
        },
        hsl: {
          fraction: {
            h: 0.23360655737704927,
            s: 0.7922077922077922,
            l: 0.30196078431372547,
          },
          h: 84,
          s: 79,
          l: 30,
          value: "hsl(84, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.23360655737704927,
            s: 0.8840579710144928,
            v: 0.5411764705882353,
          },
          value: "hsv(84, 88%, 54%)",
          h: 84,
          s: 88,
          v: 54,
        },
        name: {
          value: "Vida Loca",
          closest_named_hex: "#549019",
          exact_match_name: false,
          distance: 1330,
        },
        cmyk: {
          fraction: {
            c: 0.3550724637681159,
            m: 0,
            y: 0.8840579710144928,
            k: 0.45882352941176474,
          },
          value: "cmyk(36, 0, 88, 46)",
          c: 36,
          m: 0,
          y: 88,
          k: 46,
        },
        XYZ: {
          fraction: {
            X: 0.3487858823529411,
            Y: 0.46578117647058814,
            Z: 0.1308835294117647,
          },
          value: "XYZ(35, 47, 13)",
          X: 35,
          Y: 47,
          Z: 13,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=598A10",
          named: "https://www.thecolorapi.com/id?format=svg&hex=598A10",
        },
        contrast: { value: "#000000" },
        _links: { self: { href: "/id?hex=598A10" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#239010", clean: "239010" },
        rgb: {
          fraction: {
            r: 0.13725490196078433,
            g: 0.5647058823529412,
            b: 0.06274509803921569,
          },
          r: 35,
          g: 144,
          b: 16,
          value: "rgb(35, 144, 16)",
        },
        hsl: {
          fraction: { h: 0.3085937499999999, s: 0.8, l: 0.3137254901960784 },
          h: 111,
          s: 80,
          l: 31,
          value: "hsl(111, 80%, 31%)",
        },
        hsv: {
          fraction: {
            h: 0.3085937499999999,
            s: 0.8888888888888888,
            v: 0.5647058823529412,
          },
          value: "hsv(111, 89%, 56%)",
          h: 111,
          s: 89,
          v: 56,
        },
        name: {
          value: "Slimy Green",
          closest_named_hex: "#299617",
          exact_match_name: false,
          distance: 771,
        },
        cmyk: {
          fraction: {
            c: 0.7569444444444445,
            m: 0,
            y: 0.8888888888888888,
            k: 0.43529411764705883,
          },
          value: "cmyk(76, 0, 89, 44)",
          c: 76,
          m: 0,
          y: 89,
          k: 44,
        },
        XYZ: {
          fraction: {
            X: 0.26986823529411763,
            Y: 0.4375882352941176,
            Z: 0.12960117647058825,
          },
          value: "XYZ(27, 44, 13)",
          X: 27,
          Y: 44,
          Z: 13,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=239010",
          named: "https://www.thecolorapi.com/id?format=svg&hex=239010",
        },
        contrast: { value: "#000000" },
        _links: { self: { href: "/id?hex=239010" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=analogic-complement&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=analogic-complement&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=analogic-complement&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "triad",
    count: "5",
    colors: [
      {
        hex: { value: "#116714", clean: "116714" },
        rgb: {
          fraction: {
            r: 0.06666666666666667,
            g: 0.403921568627451,
            b: 0.0784313725490196,
          },
          r: 17,
          g: 103,
          b: 20,
          value: "rgb(17, 103, 20)",
        },
        hsl: {
          fraction: {
            h: 0.3391472868217055,
            s: 0.7166666666666667,
            l: 0.23529411764705882,
          },
          h: 122,
          s: 72,
          l: 24,
          value: "hsl(122, 72%, 24%)",
        },
        hsv: {
          fraction: {
            h: 0.3391472868217055,
            s: 0.8349514563106797,
            v: 0.403921568627451,
          },
          value: "hsv(122, 83%, 40%)",
          h: 122,
          s: 83,
          v: 40,
        },
        name: {
          value: "Green House",
          closest_named_hex: "#24500F",
          exact_match_name: false,
          distance: 1831,
        },
        cmyk: {
          fraction: {
            c: 0.8349514563106797,
            m: 0,
            y: 0.8058252427184468,
            k: 0.596078431372549,
          },
          value: "cmyk(83, 0, 81, 60)",
          c: 83,
          m: 0,
          y: 81,
          k: 60,
        },
        XYZ: {
          fraction: {
            X: 0.18609254901960784,
            Y: 0.30872078431372546,
            Z: 0.12398313725490195,
          },
          value: "XYZ(19, 31, 12)",
          X: 19,
          Y: 31,
          Z: 12,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116714",
          named: "https://www.thecolorapi.com/id?format=svg&hex=116714",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=116714" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#181174", clean: "181174" },
        rgb: {
          fraction: {
            r: 0.09411764705882353,
            g: 0.06666666666666667,
            b: 0.4549019607843137,
          },
          r: 24,
          g: 17,
          b: 116,
          value: "rgb(24, 17, 116)",
        },
        hsl: {
          fraction: {
            h: 0.6784511784511786,
            s: 0.7443609022556391,
            l: 0.2607843137254902,
          },
          h: 244,
          s: 74,
          l: 26,
          value: "hsl(244, 74%, 26%)",
        },
        hsv: {
          fraction: {
            h: 0.6784511784511786,
            s: 0.853448275862069,
            v: 0.4549019607843137,
          },
          value: "hsv(244, 85%, 45%)",
          h: 244,
          s: 85,
          v: 45,
        },
        name: {
          value: "Deep Koamaru",
          closest_named_hex: "#1B127B",
          exact_match_name: false,
          distance: 91,
        },
        cmyk: {
          fraction: {
            c: 0.793103448275862,
            m: 0.853448275862069,
            y: 0,
            k: 0.5450980392156863,
          },
          value: "cmyk(79, 85, 0, 55)",
          c: 79,
          m: 85,
          y: 0,
          k: 55,
        },
        XYZ: {
          fraction: {
            X: 0.14476392156862744,
            Y: 0.10053333333333332,
            Z: 0.44214745098039215,
          },
          value: "XYZ(14, 10, 44)",
          X: 14,
          Y: 10,
          Z: 44,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=181174",
          named: "https://www.thecolorapi.com/id?format=svg&hex=181174",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=181174" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#811711", clean: "811711" },
        rgb: {
          fraction: {
            r: 0.5058823529411764,
            g: 0.09019607843137255,
            b: 0.06666666666666667,
          },
          r: 129,
          g: 23,
          b: 17,
          value: "rgb(129, 23, 17)",
        },
        hsl: {
          fraction: {
            h: 0.008928571428571508,
            s: 0.767123287671233,
            l: 0.28627450980392155,
          },
          h: 3,
          s: 77,
          l: 29,
          value: "hsl(3, 77%, 29%)",
        },
        hsv: {
          fraction: {
            h: 0.008928571428571508,
            s: 0.8682170542635659,
            v: 0.5058823529411764,
          },
          value: "hsv(3, 87%, 51%)",
          h: 3,
          s: 87,
          v: 51,
        },
        name: {
          value: "Pueblo",
          closest_named_hex: "#7D2C14",
          exact_match_name: false,
          distance: 808,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8217054263565892,
            y: 0.8682170542635659,
            k: 0.49411764705882355,
          },
          value: "cmyk(0, 82, 87, 49)",
          c: 0,
          m: 82,
          y: 87,
          k: 49,
        },
        XYZ: {
          fraction: {
            X: 0.2529133333333333,
            Y: 0.1768721568627451,
            Z: 0.08388156862745098,
          },
          value: "XYZ(25, 18, 8)",
          X: 25,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=811711",
          named: "https://www.thecolorapi.com/id?format=svg&hex=811711",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=811711" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#8E1710", clean: "8E1710" },
        rgb: {
          fraction: {
            r: 0.5568627450980392,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 142,
          g: 23,
          b: 16,
          value: "rgb(142, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.7974683544303798,
            l: 0.30980392156862746,
          },
          h: 3,
          s: 80,
          l: 31,
          value: "hsl(3, 80%, 31%)",
        },
        hsv: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.8873239436619719,
            v: 0.5568627450980392,
          },
          value: "hsv(3, 89%, 56%)",
          h: 3,
          s: 89,
          v: 56,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 261,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8380281690140845,
            y: 0.8873239436619719,
            k: 0.44313725490196076,
          },
          value: "cmyk(0, 84, 89, 44)",
          c: 0,
          m: 84,
          y: 89,
          k: 44,
        },
        XYZ: {
          fraction: {
            X: 0.2732298039215686,
            Y: 0.18742745098039215,
            Z: 0.08113803921568627,
          },
          value: "XYZ(27, 19, 8)",
          X: 27,
          Y: 19,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=8E1710" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#9B1710", clean: "9B1710" },
        rgb: {
          fraction: {
            r: 0.6078431372549019,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 155,
          g: 23,
          b: 16,
          value: "rgb(155, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.8128654970760234,
            l: 0.3352941176470588,
          },
          h: 3,
          s: 81,
          l: 34,
          value: "hsl(3, 81%, 34%)",
        },
        hsv: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.896774193548387,
            v: 0.6078431372549019,
          },
          value: "hsv(3, 90%, 61%)",
          h: 3,
          s: 90,
          v: 61,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 184,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8516129032258064,
            y: 0.896774193548387,
            k: 0.3921568627450981,
          },
          value: "cmyk(0, 85, 90, 39)",
          c: 0,
          m: 85,
          y: 90,
          k: 39,
        },
        XYZ: {
          fraction: {
            X: 0.2942541176470588,
            Y: 0.19826588235294118,
            Z: 0.08212196078431372,
          },
          value: "XYZ(29, 20, 8)",
          X: 29,
          Y: 20,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=9B1710" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=triad&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=triad&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=triad&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
  {
    mode: "quad",
    count: "5",
    colors: [
      {
        hex: { value: "#386711", clean: "386711" },
        rgb: {
          fraction: {
            r: 0.2196078431372549,
            g: 0.403921568627451,
            b: 0.06666666666666667,
          },
          r: 56,
          g: 103,
          b: 17,
          value: "rgb(56, 103, 17)",
        },
        hsl: {
          fraction: {
            h: 0.25775193798449625,
            s: 0.7166666666666667,
            l: 0.23529411764705882,
          },
          h: 93,
          s: 72,
          l: 24,
          value: "hsl(93, 72%, 24%)",
        },
        hsv: {
          fraction: {
            h: 0.25775193798449625,
            s: 0.8349514563106797,
            v: 0.403921568627451,
          },
          value: "hsv(93, 83%, 40%)",
          h: 93,
          s: 83,
          v: 40,
        },
        name: {
          value: "Dell",
          closest_named_hex: "#396413",
          exact_match_name: false,
          distance: 178,
        },
        cmyk: {
          fraction: {
            c: 0.4563106796116505,
            m: 0,
            y: 0.8349514563106797,
            k: 0.596078431372549,
          },
          value: "cmyk(46, 0, 83, 60)",
          c: 46,
          m: 0,
          y: 83,
          k: 60,
        },
        XYZ: {
          fraction: {
            X: 0.24704196078431373,
            Y: 0.34038666666666667,
            Z: 0.11575254901960785,
          },
          value: "XYZ(25, 34, 12)",
          X: 25,
          Y: 34,
          Z: 12,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=386711",
          named: "https://www.thecolorapi.com/id?format=svg&hex=386711",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=386711" } },
        _embedded: {},
        target: "secondary",
      },
      {
        hex: { value: "#116F74", clean: "116F74" },
        rgb: {
          fraction: {
            r: 0.06666666666666667,
            g: 0.43529411764705883,
            b: 0.4549019607843137,
          },
          r: 17,
          g: 111,
          b: 116,
          value: "rgb(17, 111, 116)",
        },
        hsl: {
          fraction: {
            h: 0.5084175084175084,
            s: 0.7443609022556391,
            l: 0.2607843137254902,
          },
          h: 183,
          s: 74,
          l: 26,
          value: "hsl(183, 74%, 26%)",
        },
        hsv: {
          fraction: {
            h: 0.5084175084175084,
            s: 0.853448275862069,
            v: 0.4549019607843137,
          },
          value: "hsv(183, 85%, 45%)",
          h: 183,
          s: 85,
          v: 45,
        },
        name: {
          value: "Genoa",
          closest_named_hex: "#15736B",
          exact_match_name: false,
          distance: 531,
        },
        cmyk: {
          fraction: {
            c: 0.853448275862069,
            m: 0.04310344827586192,
            y: 0,
            k: 0.5450980392156863,
          },
          value: "cmyk(85, 4, 0, 55)",
          c: 85,
          m: 4,
          y: 0,
          k: 55,
        },
        XYZ: {
          fraction: {
            X: 0.2652643137254902,
            Y: 0.35833960784313723,
            Z: 0.48555803921568624,
          },
          value: "XYZ(27, 36, 49)",
          X: 27,
          Y: 36,
          Z: 49,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116F74",
          named: "https://www.thecolorapi.com/id?format=svg&hex=116F74",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=116F74" } },
        _embedded: {},
        target: "error",
      },
      {
        hex: { value: "#4E1181", clean: "4E1181" },
        rgb: {
          fraction: {
            r: 0.3058823529411765,
            g: 0.06666666666666667,
            b: 0.5058823529411764,
          },
          r: 78,
          g: 17,
          b: 129,
          value: "rgb(78, 17, 129)",
        },
        hsl: {
          fraction: {
            h: 0.7574404761904764,
            s: 0.767123287671233,
            l: 0.28627450980392155,
          },
          h: 273,
          s: 77,
          l: 29,
          value: "hsl(273, 77%, 29%)",
        },
        hsv: {
          fraction: {
            h: 0.7574404761904764,
            s: 0.8682170542635659,
            v: 0.5058823529411764,
          },
          value: "hsv(273, 87%, 51%)",
          h: 273,
          s: 87,
          v: 51,
        },
        name: {
          value: "Pixie Powder",
          closest_named_hex: "#391285",
          exact_match_name: false,
          distance: 630,
        },
        cmyk: {
          fraction: {
            c: 0.39534883720930225,
            m: 0.8682170542635659,
            y: 0,
            k: 0.49411764705882355,
          },
          value: "cmyk(40, 87, 0, 49)",
          c: 40,
          m: 87,
          y: 0,
          k: 49,
        },
        XYZ: {
          fraction: {
            X: 0.24129764705882353,
            Y: 0.14923529411764705,
            Z: 0.49469137254901957,
          },
          value: "XYZ(24, 15, 49)",
          X: 24,
          Y: 15,
          Z: 49,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=4E1181",
          named: "https://www.thecolorapi.com/id?format=svg&hex=4E1181",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=4E1181" } },
        _embedded: {},
        target: "warning",
      },
      {
        hex: { value: "#8E1710", clean: "8E1710" },
        rgb: {
          fraction: {
            r: 0.5568627450980392,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 142,
          g: 23,
          b: 16,
          value: "rgb(142, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.7974683544303798,
            l: 0.30980392156862746,
          },
          h: 3,
          s: 80,
          l: 31,
          value: "hsl(3, 80%, 31%)",
        },
        hsv: {
          fraction: {
            h: 0.00925925925925919,
            s: 0.8873239436619719,
            v: 0.5568627450980392,
          },
          value: "hsv(3, 89%, 56%)",
          h: 3,
          s: 89,
          v: 56,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 261,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8380281690140845,
            y: 0.8873239436619719,
            k: 0.44313725490196076,
          },
          value: "cmyk(0, 84, 89, 44)",
          c: 0,
          m: 84,
          y: 89,
          k: 44,
        },
        XYZ: {
          fraction: {
            X: 0.2732298039215686,
            Y: 0.18742745098039215,
            Z: 0.08113803921568627,
          },
          value: "XYZ(27, 19, 8)",
          X: 27,
          Y: 19,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=8E1710" } },
        _embedded: {},
        target: "info",
      },
      {
        hex: { value: "#9B1710", clean: "9B1710" },
        rgb: {
          fraction: {
            r: 0.6078431372549019,
            g: 0.09019607843137255,
            b: 0.06274509803921569,
          },
          r: 155,
          g: 23,
          b: 16,
          value: "rgb(155, 23, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.8128654970760234,
            l: 0.3352941176470588,
          },
          h: 3,
          s: 81,
          l: 34,
          value: "hsl(3, 81%, 34%)",
        },
        hsv: {
          fraction: {
            h: 0.008393285371702697,
            s: 0.896774193548387,
            v: 0.6078431372549019,
          },
          value: "hsv(3, 90%, 61%)",
          h: 3,
          s: 90,
          v: 61,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 184,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.8516129032258064,
            y: 0.896774193548387,
            k: 0.3921568627450981,
          },
          value: "cmyk(0, 85, 90, 39)",
          c: 0,
          m: 85,
          y: 90,
          k: 39,
        },
        XYZ: {
          fraction: {
            X: 0.2942541176470588,
            Y: 0.19826588235294118,
            Z: 0.08212196078431372,
          },
          value: "XYZ(29, 20, 8)",
          X: 29,
          Y: 20,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
          named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=9B1710" } },
        _embedded: {},
        target: "success",
      },
    ],
    seed: {
      hex: { value: "#881610", clean: "881610" },
      rgb: {
        fraction: {
          r: 0.5333333333333333,
          g: 0.08627450980392157,
          b: 0.06274509803921569,
        },
        r: 136,
        g: 22,
        b: 16,
        value: "rgb(136, 22, 16)",
      },
      hsl: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.7894736842105263,
          l: 0.2980392156862745,
        },
        h: 3,
        s: 79,
        l: 30,
        value: "hsl(3, 79%, 30%)",
      },
      hsv: {
        fraction: {
          h: 0.008333333333333304,
          s: 0.8823529411764706,
          v: 0.5333333333333333,
        },
        value: "hsv(3, 88%, 53%)",
        h: 3,
        s: 88,
        v: 53,
      },
      name: {
        value: "Tamarillo",
        closest_named_hex: "#991613",
        exact_match_name: false,
        distance: 486,
      },
      cmyk: {
        fraction: {
          c: 0,
          m: 0.838235294117647,
          y: 0.8823529411764706,
          k: 0.4666666666666667,
        },
        value: "cmyk(0, 84, 88, 47)",
        c: 0,
        m: 84,
        y: 88,
        k: 47,
      },
      XYZ: {
        fraction: {
          X: 0.2621239215686274,
          Y: 0.17962039215686276,
          Z: 0.08021647058823529,
        },
        value: "XYZ(26, 18, 8)",
        X: 26,
        Y: 18,
        Z: 8,
      },
      image: {
        bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
        named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
      },
      contrast: { value: "#ffffff" },
      _links: { self: { href: "/id?hex=881610" } },
      _embedded: {},
    },
    image: {
      bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=quad&count=5",
      named:
        "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=quad&count=5",
    },
    _links: {
      self: "/scheme?hex=881610&mode=quad&count=5",
      schemes: {
        monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
        "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
        "monochrome-light": "/scheme?hex=881610&mode=monochrome-light&count=5",
        analogic: "/scheme?hex=881610&mode=analogic&count=5",
        complement: "/scheme?hex=881610&mode=complement&count=5",
        "analogic-complement":
          "/scheme?hex=881610&mode=analogic-complement&count=5",
        triad: "/scheme?hex=881610&mode=triad&count=5",
        quad: "/scheme?hex=881610&mode=quad&count=5",
      },
    },
    _embedded: {},
  },
];

export const DefaultTheme = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
    unit: "px",
  },
  direction: "ltr",
  components: {},
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#881610",
      light: "#983631",
      dark: "#74120d",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    secondary: {
      main: "#116367",
      light: "#32787c",
      dark: "#0e5558",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    error: {
      main: "#9B1710",
      light: "#a93731",
      dark: "#85130d",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    warning: {
      main: "#8cbf2f",
      light: "#9cc74c",
      dark: "#78a428",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    info: {
      main: "#116367",
      light: "#32787c",
      dark: "#0e5558",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    success: {
      main: "#116F74",
      light: "#328387",
      dark: "#0e5f63",
      contrastText: "rgb(255, 255, 255)",
      contrastDark: "rgb(255, 255, 255)",
      contrastLight: "rgb(255, 255, 255)",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.14,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fff",
      default: "#fff",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    muiToolShades: {
      main: "#84b726",
      dark: "rgb(0, 0, 0)",
      light: "rgb(255, 255, 255)",
      contrastHexDark: "rgb(255, 255, 255)",
      contrastHexLight: "rgb(0, 0, 0)",
      contrastHexMain: "rgb(255, 255, 255)",
    },
  },
  shape: { borderRadius: 4 },
  test: "value",
  debug: { display: "none", fontWeight: 800 },
  form: { padding: 10, width: "10%" },
  userCard: { opacity: ".9", margin: 3, padding: 3, width: "50%" },
  badge: {
    whiteSpace: "nowrap",
    maxWidth: 300,
    fontWeight: 600,
    borderRadius: 3,
    padding: 2,
    margin: 3,
    textTransform: "lowercase",
  },
  submitButton: {
    border: "1px solid grey",
    padding: 3,
    fontWeight: 600,
    margin: 3,
    whiteSpace: "nowrap",
    width: 50,
    height: 60,
    borderRadius: 500,
  },
  loadMoreButton: {
    height: "60px",
    borderRadius: 3,
    border: 0,
    margin: "2 3 3 2",
    padding: "0 0 0 0",
  },
  flexRows: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  errorMessage: { position: "fixed", left: 5, top: "140px", width: "50%" },
  loading: { width: "100%", zIndex: 2000, "& > * + *": { marginLeft: 2 } },
  loud: { fontWeight: 800 },
  siteLink: {
    borderRadius: 5,
    padding: 3,
    whiteSpace: "nowrap",
    maxHeight: 60,
    textDecoration: "none",
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": { minHeight: 48 },
      "@media (min-width:600px)": { minHeight: 64 },
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 500,
      fontSize: "1.2rem",
      lineHeight: 1.8,
      letterSpacing: "0.00110",
    },
    body2: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "'PT Sans Caption'",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  defaultThemeId: "1",
  global: {
    __typename: "Global",
    eventFillStart: null,
    favicon: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        id: "74",
        attributes: {
          __typename: "UploadFile",
          alternativeText: null,
          width: 2105,
          height: 2109,
          mime: "image/webp",
          url: "/uploads/handdrawn_square_4_tone_1727538e1f.webp",
          formats: {
            large: {
              ext: ".webp",
              url: "/uploads/large_handdrawn_square_4_tone_1727538e1f.webp",
              hash: "large_handdrawn_square_4_tone_1727538e1f",
              mime: "image/webp",
              name: "large_handdrawn_square_4_tone.webp",
              path: null,
              size: 86.18,
              width: 998,
              height: 1000,
            },
            small: {
              ext: ".webp",
              url: "/uploads/small_handdrawn_square_4_tone_1727538e1f.webp",
              hash: "small_handdrawn_square_4_tone_1727538e1f",
              mime: "image/webp",
              name: "small_handdrawn_square_4_tone.webp",
              path: null,
              size: 26.23,
              width: 499,
              height: 500,
            },
            medium: {
              ext: ".webp",
              url: "/uploads/medium_handdrawn_square_4_tone_1727538e1f.webp",
              hash: "medium_handdrawn_square_4_tone_1727538e1f",
              mime: "image/webp",
              name: "medium_handdrawn_square_4_tone.webp",
              path: null,
              size: 51.45,
              width: 749,
              height: 750,
            },
            thumbnail: {
              ext: ".webp",
              url: "/uploads/thumbnail_handdrawn_square_4_tone_1727538e1f.webp",
              hash: "thumbnail_handdrawn_square_4_tone_1727538e1f",
              mime: "image/webp",
              name: "thumbnail_handdrawn_square_4_tone.webp",
              path: null,
              size: 3.74,
              width: 156,
              height: 156,
            },
          },
        },
      },
    },
    metadata: {
      __typename: "ComponentMetaMetadata",
      metaTitle: "Chattanooga Traditional Dance Society",
      metaDescription:
        "The Chattanooga Traditional Dance Society (CTDS) hosts regular contra dances on the second and fourth Saturday's. Lesson starts at 7:30 and the dance starts at 8:00. This site features local and regional events, videos and articles.",
    },
    metaTitleSuffix: "Chattanooga Traditional Dance Society",
    notificationBanner: null,
    navbar: {
      __typename: "ComponentLayoutNavbar",
      logo: {
        __typename: "UploadFileEntityResponse",
        data: {
          __typename: "UploadFileEntity",
          id: "74",
          attributes: {
            __typename: "UploadFile",
            alternativeText: null,
            width: 2105,
            height: 2109,
            mime: "image/webp",
            url: "/uploads/handdrawn_square_4_tone_1727538e1f.webp",
            formats: {
              large: {
                ext: ".webp",
                url: "/uploads/large_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "large_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "large_handdrawn_square_4_tone.webp",
                path: null,
                size: 86.18,
                width: 998,
                height: 1000,
              },
              small: {
                ext: ".webp",
                url: "/uploads/small_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "small_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "small_handdrawn_square_4_tone.webp",
                path: null,
                size: 26.23,
                width: 499,
                height: 500,
              },
              medium: {
                ext: ".webp",
                url: "/uploads/medium_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "medium_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "medium_handdrawn_square_4_tone.webp",
                path: null,
                size: 51.45,
                width: 749,
                height: 750,
              },
              thumbnail: {
                ext: ".webp",
                url: "/uploads/thumbnail_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "thumbnail_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "thumbnail_handdrawn_square_4_tone.webp",
                path: null,
                size: 3.74,
                width: 156,
                height: 156,
              },
            },
          },
        },
      },
      links: [
        {
          __typename: "ComponentLinksLink",
          id: "1",
          url: "/",
          newTab: false,
          description: null,
          text: "Home",
        },
        {
          __typename: "ComponentLinksLink",
          id: "2",
          url: "/page/ctdscalendar",
          newTab: false,
          description: null,
          text: "Events",
        },
      ],
      dropdown: [
        {
          __typename: "ComponentLinksDropdown",
          id: "2",
          text: "Dancing",
          title: null,
          url: null,
          Link: [
            {
              __typename: "ComponentLinksLink",
              id: "4",
              url: "/page/beginners",
              newTab: false,
              text: "Tips for beginners",
              slug: "tips",
              description: null,
            },
            {
              __typename: "ComponentLinksLink",
              id: "13",
              url: "/page/lessons",
              newTab: false,
              text: "Video Lessons",
              slug: "lessons",
              description: null,
            },
            {
              __typename: "ComponentLinksLink",
              id: "26",
              url: "/page/videos",
              newTab: false,
              text: "Dance Videos",
              slug: "videos",
              description: null,
            },
            {
              __typename: "ComponentLinksLink",
              id: "32",
              url: "/page/history",
              newTab: false,
              text: "History",
              slug: "history",
              description: "history",
            },
          ],
        },
        {
          __typename: "ComponentLinksDropdown",
          id: "1",
          text: "more...",
          title: null,
          url: null,
          Link: [
            {
              __typename: "ComponentLinksLink",
              id: "3",
              url: "/pages/pastdancers",
              newTab: false,
              text: "Past Dancers",
              slug: "pastdancers",
              description: null,
            },
            {
              __typename: "ComponentLinksLink",
              id: "5",
              url: "/pages/bod",
              newTab: false,
              text: "Board",
              slug: "bod",
              description: null,
            },
          ],
        },
      ],
      button: null,
    },
    footer: {
      __typename: "ComponentLayoutFooter",
      logo: {
        __typename: "UploadFileEntityResponse",
        data: {
          __typename: "UploadFileEntity",
          id: "74",
          attributes: {
            __typename: "UploadFile",
            alternativeText: null,
            width: 2105,
            height: 2109,
            mime: "image/webp",
            url: "/uploads/handdrawn_square_4_tone_1727538e1f.webp",
            formats: {
              large: {
                ext: ".webp",
                url: "/uploads/large_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "large_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "large_handdrawn_square_4_tone.webp",
                path: null,
                size: 86.18,
                width: 998,
                height: 1000,
              },
              small: {
                ext: ".webp",
                url: "/uploads/small_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "small_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "small_handdrawn_square_4_tone.webp",
                path: null,
                size: 26.23,
                width: 499,
                height: 500,
              },
              medium: {
                ext: ".webp",
                url: "/uploads/medium_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "medium_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "medium_handdrawn_square_4_tone.webp",
                path: null,
                size: 51.45,
                width: 749,
                height: 750,
              },
              thumbnail: {
                ext: ".webp",
                url: "/uploads/thumbnail_handdrawn_square_4_tone_1727538e1f.webp",
                hash: "thumbnail_handdrawn_square_4_tone_1727538e1f",
                mime: "image/webp",
                name: "thumbnail_handdrawn_square_4_tone.webp",
                path: null,
                size: 3.74,
                width: 156,
                height: 156,
              },
            },
          },
        },
      },
      smallText: "© COPYRIGHT 2023. ALL RIGHTS RESERVED",
      columns: [
        {
          __typename: "ComponentElementsFooterSection",
          id: "1",
          title: "Credits",
          links: [
            {
              __typename: "ComponentLinksButtonFooter",
              id: "1",
              url: "https://cdss.org/",
              button_label: "CDSS.org",
              text: "We belong to the Country Dance & Song Society CDSS ",
              type: "image",
              image: {
                __typename: "UploadFileEntityResponse",
                data: {
                  __typename: "UploadFileEntity",
                  id: "86",
                  attributes: {
                    __typename: "UploadFile",
                    alternativeText: null,
                    width: 342,
                    height: 342,
                    mime: "image/png",
                    url: "/uploads/cdss_logo_d72bfe5732.png",
                    formats: {
                      thumbnail: {
                        ext: ".png",
                        url: "/uploads/thumbnail_cdss_logo_d72bfe5732.png",
                        hash: "thumbnail_cdss_logo_d72bfe5732",
                        mime: "image/png",
                        name: "thumbnail_cdss-logo.png",
                        path: null,
                        size: 20.9,
                        width: 156,
                        height: 156,
                      },
                    },
                  },
                },
              },
            },
            {
              __typename: "ComponentLinksButtonFooter",
              id: "2",
              url: "https://www.youtube.com/channel/UChYJngkH8ZBaMIxPpLc1JIA",
              button_label: "Jim on Youtube",
              text: "Thanks to Jim Crawford for his many videos",
              type: "button",
              image: { __typename: "UploadFileEntityResponse", data: null },
            },
          ],
        },
      ],
    },
  },
  feature: {
    __typename: "Feature",
    heading: "",
    body: "",
    caption1: "",
    caption2: "Summer schedule through August 8",
    picture1: {
      __typename: "UploadFileRelationResponseCollection",
      data: [
        {
          __typename: "UploadFileEntity",
          id: "40",
          attributes: {
            __typename: "UploadFile",
            name: "One_service.png",
            width: 940,
            height: 788,
            formats: {
              small: {
                ext: ".png",
                url: "/uploads/small_One_service_8f4ba29e73.png",
                hash: "small_One_service_8f4ba29e73",
                mime: "image/png",
                name: "small_One_service.png",
                path: null,
                size: 452.21,
                width: 500,
                height: 419,
              },
              medium: {
                ext: ".png",
                url: "/uploads/medium_One_service_8f4ba29e73.png",
                hash: "medium_One_service_8f4ba29e73",
                mime: "image/png",
                name: "medium_One_service.png",
                path: null,
                size: 969.56,
                width: 750,
                height: 629,
              },
              thumbnail: {
                ext: ".png",
                url: "/uploads/thumbnail_One_service_8f4ba29e73.png",
                hash: "thumbnail_One_service_8f4ba29e73",
                mime: "image/png",
                name: "thumbnail_One_service.png",
                path: null,
                size: 69.83,
                width: 186,
                height: 156,
              },
            },
          },
        },
      ],
    },
    picture2: { __typename: "UploadFileRelationResponseCollection", data: [] },
  },
  access_token:
    "EAAPfw7gqiE8BABfm8ZClked38glbRCq4rHJKIdnO6M6FhcMHxx9xR5xTyYTcJuojnoeklS3tE5sQc64PDxzSXf4ErQUmz9rIMxWYSPwUdoG50mNTajgI2H1GFiZAXeToMuwUMaT35YFKO8jUHWFIvOLhWFzowVDPTz0QZBoGq0DeuJC7axGkh16pIRP38wZD",
  themeId: "1",
  color_modes: [
    {
      mode: "monochrome",
      count: "5",
      colors: [
        {
          hex: { value: "#160403", clean: "160403" },
          rgb: {
            fraction: {
              r: 0.08627450980392157,
              g: 0.01568627450980392,
              b: 0.011764705882352941,
            },
            r: 22,
            g: 4,
            b: 3,
            value: "rgb(22, 4, 3)",
          },
          hsl: {
            fraction: {
              h: 0.00877192982456132,
              s: 0.76,
              l: 0.049019607843137254,
            },
            h: 3,
            s: 76,
            l: 5,
            value: "hsl(3, 76%, 5%)",
          },
          hsv: {
            fraction: {
              h: 0.00877192982456132,
              s: 0.8636363636363636,
              v: 0.08627450980392157,
            },
            value: "hsv(3, 86%, 9%)",
            h: 3,
            s: 86,
            v: 9,
          },
          name: {
            value: "Creole",
            closest_named_hex: "#1E0F04",
            exact_match_name: false,
            distance: 694,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8181818181818189,
              y: 0.8636363636363642,
              k: 0.9137254901960784,
            },
            value: "cmyk(0, 82, 86, 91)",
            c: 0,
            m: 82,
            y: 86,
            k: 91,
          },
          XYZ: {
            fraction: {
              X: 0.04331254901960784,
              Y: 0.030410196078431373,
              Z: 0.014717254901960785,
            },
            value: "XYZ(4, 3, 1)",
            X: 4,
            Y: 3,
            Z: 1,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=160403",
            named: "https://www.thecolorapi.com/id?format=svg&hex=160403",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=160403" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#430C09", clean: "430C09" },
          rgb: {
            fraction: {
              r: 0.2627450980392157,
              g: 0.047058823529411764,
              b: 0.03529411764705882,
            },
            r: 67,
            g: 12,
            b: 9,
            value: "rgb(67, 12, 9)",
          },
          hsl: {
            fraction: {
              h: 0.008620689655172376,
              s: 0.7631578947368421,
              l: 0.14901960784313725,
            },
            h: 3,
            s: 76,
            l: 15,
            value: "hsl(3, 76%, 15%)",
          },
          hsv: {
            fraction: {
              h: 0.008620689655172376,
              s: 0.8656716417910448,
              v: 0.2627450980392157,
            },
            value: "hsv(3, 87%, 26%)",
            h: 3,
            s: 87,
            v: 26,
          },
          name: {
            value: "Aubergine",
            closest_named_hex: "#3B0910",
            exact_match_name: false,
            distance: 350,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8208955223880594,
              y: 0.8656716417910448,
              k: 0.7372549019607844,
            },
            value: "cmyk(0, 82, 87, 74)",
            c: 0,
            m: 82,
            y: 87,
            k: 74,
          },
          XYZ: {
            fraction: {
              X: 0.13155490196078434,
              Y: 0.09206431372549019,
              Z: 0.04422745098039216,
            },
            value: "XYZ(13, 9, 4)",
            X: 13,
            Y: 9,
            Z: 4,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=430C09",
            named: "https://www.thecolorapi.com/id?format=svg&hex=430C09",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=430C09" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#71130E", clean: "71130E" },
          rgb: {
            fraction: {
              r: 0.44313725490196076,
              g: 0.07450980392156863,
              b: 0.054901960784313725,
            },
            r: 113,
            g: 19,
            b: 14,
            value: "rgb(113, 19, 14)",
          },
          hsl: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.7795275590551181,
              l: 0.24901960784313726,
            },
            h: 3,
            s: 78,
            l: 25,
            value: "hsl(3, 78%, 25%)",
          },
          hsv: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.8761061946902654,
              v: 0.44313725490196076,
            },
            value: "hsv(3, 88%, 44%)",
            h: 3,
            s: 88,
            v: 44,
          },
          name: {
            value: "Dark Tan",
            closest_named_hex: "#661010",
            exact_match_name: false,
            distance: 512,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8318584070796461,
              y: 0.8761061946902654,
              k: 0.5568627450980392,
            },
            value: "cmyk(0, 83, 88, 56)",
            c: 0,
            m: 83,
            y: 88,
            k: 56,
          },
          XYZ: {
            fraction: {
              X: 0.21930431372549017,
              Y: 0.15146431372549019,
              Z: 0.06961843137254903,
            },
            value: "XYZ(22, 15, 7)",
            X: 22,
            Y: 15,
            Z: 7,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
            named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=71130E" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#9F1A13", clean: "9F1A13" },
          rgb: {
            fraction: {
              r: 0.6235294117647059,
              g: 0.10196078431372549,
              b: 0.07450980392156863,
            },
            r: 159,
            g: 26,
            b: 19,
            value: "rgb(159, 26, 19)",
          },
          hsl: {
            fraction: {
              h: 0.008333333333333304,
              s: 0.7865168539325844,
              l: 0.34901960784313724,
            },
            h: 3,
            s: 79,
            l: 35,
            value: "hsl(3, 79%, 35%)",
          },
          hsv: {
            fraction: {
              h: 0.008333333333333304,
              s: 0.8805031446540881,
              v: 0.6235294117647059,
            },
            value: "hsv(3, 88%, 62%)",
            h: 3,
            s: 88,
            v: 62,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 100,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8364779874213837,
              y: 0.8805031446540881,
              k: 0.3764705882352941,
            },
            value: "cmyk(0, 84, 88, 38)",
            c: 0,
            m: 84,
            y: 88,
            k: 38,
          },
          XYZ: {
            fraction: {
              X: 0.30705372549019605,
              Y: 0.21086431372549022,
              Z: 0.09500941176470588,
            },
            value: "XYZ(31, 21, 10)",
            X: 31,
            Y: 21,
            Z: 10,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9F1A13",
            named: "https://www.thecolorapi.com/id?format=svg&hex=9F1A13",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=9F1A13" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#CE2017", clean: "CE2017" },
          rgb: {
            fraction: {
              r: 0.807843137254902,
              g: 0.12549019607843137,
              b: 0.09019607843137255,
            },
            r: 206,
            g: 32,
            b: 23,
            value: "rgb(206, 32, 23)",
          },
          hsl: {
            fraction: {
              h: 0.00819672131147553,
              s: 0.7991266375545851,
              l: 0.44901960784313727,
            },
            h: 3,
            s: 80,
            l: 45,
            value: "hsl(3, 80%, 45%)",
          },
          hsv: {
            fraction: {
              h: 0.00819672131147553,
              s: 0.8883495145631067,
              v: 0.807843137254902,
            },
            value: "hsv(3, 89%, 81%)",
            h: 3,
            s: 89,
            v: 81,
          },
          name: {
            value: "Thunderbird",
            closest_named_hex: "#C02B18",
            exact_match_name: false,
            distance: 448,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8446601941747572,
              y: 0.8883495145631067,
              k: 0.19215686274509802,
            },
            value: "cmyk(0, 84, 89, 19)",
            c: 0,
            m: 84,
            y: 89,
            k: 19,
          },
          XYZ: {
            fraction: {
              X: 0.3943101960784314,
              Y: 0.26801019607843135,
              Z: 0.11628117647058824,
            },
            value: "XYZ(39, 27, 12)",
            X: 39,
            Y: 27,
            Z: 12,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=CE2017",
            named: "https://www.thecolorapi.com/id?format=svg&hex=CE2017",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=CE2017" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=monochrome&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "monochrome-dark",
      count: "5",
      colors: [
        {
          hex: { value: "#160403", clean: "160403" },
          rgb: {
            fraction: {
              r: 0.08627450980392157,
              g: 0.01568627450980392,
              b: 0.011764705882352941,
            },
            r: 22,
            g: 4,
            b: 3,
            value: "rgb(22, 4, 3)",
          },
          hsl: {
            fraction: {
              h: 0.00877192982456132,
              s: 0.76,
              l: 0.049019607843137254,
            },
            h: 3,
            s: 76,
            l: 5,
            value: "hsl(3, 76%, 5%)",
          },
          hsv: {
            fraction: {
              h: 0.00877192982456132,
              s: 0.8636363636363636,
              v: 0.08627450980392157,
            },
            value: "hsv(3, 86%, 9%)",
            h: 3,
            s: 86,
            v: 9,
          },
          name: {
            value: "Creole",
            closest_named_hex: "#1E0F04",
            exact_match_name: false,
            distance: 694,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8181818181818189,
              y: 0.8636363636363642,
              k: 0.9137254901960784,
            },
            value: "cmyk(0, 82, 86, 91)",
            c: 0,
            m: 82,
            y: 86,
            k: 91,
          },
          XYZ: {
            fraction: {
              X: 0.04331254901960784,
              Y: 0.030410196078431373,
              Z: 0.014717254901960785,
            },
            value: "XYZ(4, 3, 1)",
            X: 4,
            Y: 3,
            Z: 1,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=160403",
            named: "https://www.thecolorapi.com/id?format=svg&hex=160403",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=160403" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#430C09", clean: "430C09" },
          rgb: {
            fraction: {
              r: 0.2627450980392157,
              g: 0.047058823529411764,
              b: 0.03529411764705882,
            },
            r: 67,
            g: 12,
            b: 9,
            value: "rgb(67, 12, 9)",
          },
          hsl: {
            fraction: {
              h: 0.008620689655172376,
              s: 0.7631578947368421,
              l: 0.14901960784313725,
            },
            h: 3,
            s: 76,
            l: 15,
            value: "hsl(3, 76%, 15%)",
          },
          hsv: {
            fraction: {
              h: 0.008620689655172376,
              s: 0.8656716417910448,
              v: 0.2627450980392157,
            },
            value: "hsv(3, 87%, 26%)",
            h: 3,
            s: 87,
            v: 26,
          },
          name: {
            value: "Aubergine",
            closest_named_hex: "#3B0910",
            exact_match_name: false,
            distance: 350,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8208955223880594,
              y: 0.8656716417910448,
              k: 0.7372549019607844,
            },
            value: "cmyk(0, 82, 87, 74)",
            c: 0,
            m: 82,
            y: 87,
            k: 74,
          },
          XYZ: {
            fraction: {
              X: 0.13155490196078434,
              Y: 0.09206431372549019,
              Z: 0.04422745098039216,
            },
            value: "XYZ(13, 9, 4)",
            X: 13,
            Y: 9,
            Z: 4,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=430C09",
            named: "https://www.thecolorapi.com/id?format=svg&hex=430C09",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=430C09" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#71130E", clean: "71130E" },
          rgb: {
            fraction: {
              r: 0.44313725490196076,
              g: 0.07450980392156863,
              b: 0.054901960784313725,
            },
            r: 113,
            g: 19,
            b: 14,
            value: "rgb(113, 19, 14)",
          },
          hsl: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.7795275590551181,
              l: 0.24901960784313726,
            },
            h: 3,
            s: 78,
            l: 25,
            value: "hsl(3, 78%, 25%)",
          },
          hsv: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.8761061946902654,
              v: 0.44313725490196076,
            },
            value: "hsv(3, 88%, 44%)",
            h: 3,
            s: 88,
            v: 44,
          },
          name: {
            value: "Dark Tan",
            closest_named_hex: "#661010",
            exact_match_name: false,
            distance: 512,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8318584070796461,
              y: 0.8761061946902654,
              k: 0.5568627450980392,
            },
            value: "cmyk(0, 83, 88, 56)",
            c: 0,
            m: 83,
            y: 88,
            k: 56,
          },
          XYZ: {
            fraction: {
              X: 0.21930431372549017,
              Y: 0.15146431372549019,
              Z: 0.06961843137254903,
            },
            value: "XYZ(22, 15, 7)",
            X: 22,
            Y: 15,
            Z: 7,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
            named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=71130E" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#595959", clean: "595959" },
          rgb: {
            fraction: {
              r: 0.34901960784313724,
              g: 0.34901960784313724,
              b: 0.34901960784313724,
            },
            r: 89,
            g: 89,
            b: 89,
            value: "rgb(89, 89, 89)",
          },
          hsl: {
            fraction: { h: 0, s: 0, l: 0.34901960784313724 },
            h: 0,
            s: 0,
            l: 35,
            value: "hsl(0, 0%, 35%)",
          },
          hsv: {
            fraction: { h: 0, s: 0, v: 0.34901960784313724 },
            value: "hsv(0, 0%, 35%)",
            h: 0,
            s: 0,
            v: 35,
          },
          name: {
            value: "Granite Gray",
            closest_named_hex: "#676767",
            exact_match_name: false,
            distance: 980,
          },
          cmyk: {
            fraction: { c: 0, m: 0, y: 0, k: 0.6509803921568628 },
            value: "cmyk(0, 0, 0, 65)",
            c: 0,
            m: 0,
            y: 0,
            k: 65,
          },
          XYZ: {
            fraction: {
              X: 0.3317431372549019,
              Y: 0.34901960784313724,
              Z: 0.3800823529411765,
            },
            value: "XYZ(33, 35, 38)",
            X: 33,
            Y: 35,
            Z: 38,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=595959",
            named: "https://www.thecolorapi.com/id?format=svg&hex=595959",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=595959" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#727272", clean: "727272" },
          rgb: {
            fraction: {
              r: 0.4470588235294118,
              g: 0.4470588235294118,
              b: 0.4470588235294118,
            },
            r: 114,
            g: 114,
            b: 114,
            value: "rgb(114, 114, 114)",
          },
          hsl: {
            fraction: { h: 0, s: 0, l: 0.4470588235294118 },
            h: 0,
            s: 0,
            l: 45,
            value: "hsl(0, 0%, 45%)",
          },
          hsv: {
            fraction: { h: 0, s: 0, v: 0.4470588235294118 },
            value: "hsv(0, 0%, 45%)",
            h: 0,
            s: 0,
            v: 45,
          },
          name: {
            value: "Sonic Silver",
            closest_named_hex: "#757575",
            exact_match_name: false,
            distance: 45,
          },
          cmyk: {
            fraction: { c: 0, m: 0, y: 0, k: 0.5529411764705883 },
            value: "cmyk(0, 0, 0, 55)",
            c: 0,
            m: 0,
            y: 0,
            k: 55,
          },
          XYZ: {
            fraction: {
              X: 0.4249294117647059,
              Y: 0.44705882352941173,
              Z: 0.4868470588235294,
            },
            value: "XYZ(42, 45, 49)",
            X: 42,
            Y: 45,
            Z: 49,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=727272",
            named: "https://www.thecolorapi.com/id?format=svg&hex=727272",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=727272" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome-dark&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome-dark&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=monochrome-dark&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "monochrome-light",
      count: "5",
      colors: [
        {
          hex: { value: "#0C0C0C", clean: "0C0C0C" },
          rgb: {
            fraction: {
              r: 0.047058823529411764,
              g: 0.047058823529411764,
              b: 0.047058823529411764,
            },
            r: 12,
            g: 12,
            b: 12,
            value: "rgb(12, 12, 12)",
          },
          hsl: {
            fraction: { h: 0, s: 0, l: 0.047058823529411764 },
            h: 0,
            s: 0,
            l: 5,
            value: "hsl(0, 0%, 5%)",
          },
          hsv: {
            fraction: { h: 0, s: 0, v: 0.047058823529411764 },
            value: "hsv(0, 0%, 5%)",
            h: 0,
            s: 0,
            v: 5,
          },
          name: {
            value: "Cod Gray",
            closest_named_hex: "#0B0B0B",
            exact_match_name: false,
            distance: 5,
          },
          cmyk: {
            fraction: { c: 0, m: 0, y: 0, k: 0.9529411764705882 },
            value: "cmyk(0, 0, 0, 95)",
            c: 0,
            m: 0,
            y: 0,
            k: 95,
          },
          XYZ: {
            fraction: {
              X: 0.044729411764705886,
              Y: 0.047058823529411764,
              Z: 0.051247058823529416,
            },
            value: "XYZ(4, 5, 5)",
            X: 4,
            Y: 5,
            Z: 5,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=0C0C0C",
            named: "https://www.thecolorapi.com/id?format=svg&hex=0C0C0C",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=0C0C0C" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#262626", clean: "262626" },
          rgb: {
            fraction: {
              r: 0.14901960784313725,
              g: 0.14901960784313725,
              b: 0.14901960784313725,
            },
            r: 38,
            g: 38,
            b: 38,
            value: "rgb(38, 38, 38)",
          },
          hsl: {
            fraction: { h: 0, s: 0, l: 0.14901960784313725 },
            h: 0,
            s: 0,
            l: 15,
            value: "hsl(0, 0%, 15%)",
          },
          hsv: {
            fraction: { h: 0, s: 0, v: 0.14901960784313725 },
            value: "hsv(0, 0%, 15%)",
            h: 0,
            s: 0,
            v: 15,
          },
          name: {
            value: "Eerie Black",
            closest_named_hex: "#1B1B1B",
            exact_match_name: false,
            distance: 605,
          },
          cmyk: {
            fraction: { c: 0, m: 0, y: 0, k: 0.8509803921568627 },
            value: "cmyk(0, 0, 0, 85)",
            c: 0,
            m: 0,
            y: 0,
            k: 85,
          },
          XYZ: {
            fraction: {
              X: 0.14164313725490196,
              Y: 0.14901960784313723,
              Z: 0.16228235294117646,
            },
            value: "XYZ(14, 15, 16)",
            X: 14,
            Y: 15,
            Z: 16,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=262626",
            named: "https://www.thecolorapi.com/id?format=svg&hex=262626",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=262626" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#71130E", clean: "71130E" },
          rgb: {
            fraction: {
              r: 0.44313725490196076,
              g: 0.07450980392156863,
              b: 0.054901960784313725,
            },
            r: 113,
            g: 19,
            b: 14,
            value: "rgb(113, 19, 14)",
          },
          hsl: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.7795275590551181,
              l: 0.24901960784313726,
            },
            h: 3,
            s: 78,
            l: 25,
            value: "hsl(3, 78%, 25%)",
          },
          hsv: {
            fraction: {
              h: 0.008417508417508324,
              s: 0.8761061946902654,
              v: 0.44313725490196076,
            },
            value: "hsv(3, 88%, 44%)",
            h: 3,
            s: 88,
            v: 44,
          },
          name: {
            value: "Dark Tan",
            closest_named_hex: "#661010",
            exact_match_name: false,
            distance: 512,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8318584070796461,
              y: 0.8761061946902654,
              k: 0.5568627450980392,
            },
            value: "cmyk(0, 83, 88, 56)",
            c: 0,
            m: 83,
            y: 88,
            k: 56,
          },
          XYZ: {
            fraction: {
              X: 0.21930431372549017,
              Y: 0.15146431372549019,
              Z: 0.06961843137254903,
            },
            value: "XYZ(22, 15, 7)",
            X: 22,
            Y: 15,
            Z: 7,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=71130E",
            named: "https://www.thecolorapi.com/id?format=svg&hex=71130E",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=71130E" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#9F1A13", clean: "9F1A13" },
          rgb: {
            fraction: {
              r: 0.6235294117647059,
              g: 0.10196078431372549,
              b: 0.07450980392156863,
            },
            r: 159,
            g: 26,
            b: 19,
            value: "rgb(159, 26, 19)",
          },
          hsl: {
            fraction: {
              h: 0.008333333333333304,
              s: 0.7865168539325844,
              l: 0.34901960784313724,
            },
            h: 3,
            s: 79,
            l: 35,
            value: "hsl(3, 79%, 35%)",
          },
          hsv: {
            fraction: {
              h: 0.008333333333333304,
              s: 0.8805031446540881,
              v: 0.6235294117647059,
            },
            value: "hsv(3, 88%, 62%)",
            h: 3,
            s: 88,
            v: 62,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 100,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8364779874213837,
              y: 0.8805031446540881,
              k: 0.3764705882352941,
            },
            value: "cmyk(0, 84, 88, 38)",
            c: 0,
            m: 84,
            y: 88,
            k: 38,
          },
          XYZ: {
            fraction: {
              X: 0.30705372549019605,
              Y: 0.21086431372549022,
              Z: 0.09500941176470588,
            },
            value: "XYZ(31, 21, 10)",
            X: 31,
            Y: 21,
            Z: 10,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9F1A13",
            named: "https://www.thecolorapi.com/id?format=svg&hex=9F1A13",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=9F1A13" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#CE2017", clean: "CE2017" },
          rgb: {
            fraction: {
              r: 0.807843137254902,
              g: 0.12549019607843137,
              b: 0.09019607843137255,
            },
            r: 206,
            g: 32,
            b: 23,
            value: "rgb(206, 32, 23)",
          },
          hsl: {
            fraction: {
              h: 0.00819672131147553,
              s: 0.7991266375545851,
              l: 0.44901960784313727,
            },
            h: 3,
            s: 80,
            l: 45,
            value: "hsl(3, 80%, 45%)",
          },
          hsv: {
            fraction: {
              h: 0.00819672131147553,
              s: 0.8883495145631067,
              v: 0.807843137254902,
            },
            value: "hsv(3, 89%, 81%)",
            h: 3,
            s: 89,
            v: 81,
          },
          name: {
            value: "Thunderbird",
            closest_named_hex: "#C02B18",
            exact_match_name: false,
            distance: 448,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8446601941747572,
              y: 0.8883495145631067,
              k: 0.19215686274509802,
            },
            value: "cmyk(0, 84, 89, 19)",
            c: 0,
            m: 84,
            y: 89,
            k: 19,
          },
          XYZ: {
            fraction: {
              X: 0.3943101960784314,
              Y: 0.26801019607843135,
              Z: 0.11628117647058824,
            },
            value: "XYZ(39, 27, 12)",
            X: 39,
            Y: 27,
            Z: 12,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=CE2017",
            named: "https://www.thecolorapi.com/id?format=svg&hex=CE2017",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=CE2017" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=monochrome-light&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=monochrome-light&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=monochrome-light&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "analogic",
      count: "5",
      colors: [
        {
          hex: { value: "#7B1611", clean: "7B1611" },
          rgb: {
            fraction: {
              r: 0.4823529411764706,
              g: 0.08627450980392157,
              b: 0.06666666666666667,
            },
            r: 123,
            g: 22,
            b: 17,
            value: "rgb(123, 22, 17)",
          },
          hsl: {
            fraction: {
              h: 0.007861635220125729,
              s: 0.7571428571428571,
              l: 0.27450980392156865,
            },
            h: 3,
            s: 76,
            l: 27,
            value: "hsl(3, 76%, 27%)",
          },
          hsv: {
            fraction: {
              h: 0.007861635220125729,
              s: 0.8617886178861789,
              v: 0.4823529411764706,
            },
            value: "hsv(3, 86%, 48%)",
            h: 3,
            s: 86,
            v: 48,
          },
          name: {
            value: "Pueblo",
            closest_named_hex: "#7D2C14",
            exact_match_name: false,
            distance: 765,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8211382113821137,
              y: 0.8617886178861789,
              k: 0.5176470588235293,
            },
            value: "cmyk(0, 82, 86, 52)",
            c: 0,
            m: 82,
            y: 86,
            k: 52,
          },
          XYZ: {
            fraction: {
              X: 0.24180745098039216,
              Y: 0.16906509803921568,
              Z: 0.08296,
            },
            value: "XYZ(24, 17, 8)",
            X: 24,
            Y: 17,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=7B1611",
            named: "https://www.thecolorapi.com/id?format=svg&hex=7B1611",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=7B1611" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#803711", clean: "803711" },
          rgb: {
            fraction: {
              r: 0.5019607843137255,
              g: 0.21568627450980393,
              b: 0.06666666666666667,
            },
            r: 128,
            g: 55,
            b: 17,
            value: "rgb(128, 55, 17)",
          },
          hsl: {
            fraction: {
              h: 0.0570570570570571,
              s: 0.7655172413793104,
              l: 0.28431372549019607,
            },
            h: 21,
            s: 77,
            l: 28,
            value: "hsl(21, 77%, 28%)",
          },
          hsv: {
            fraction: {
              h: 0.0570570570570571,
              s: 0.8671875,
              v: 0.5019607843137255,
            },
            value: "hsv(21, 87%, 50%)",
            h: 21,
            s: 87,
            v: 50,
          },
          name: {
            value: "Copper Canyon",
            closest_named_hex: "#7E3A15",
            exact_match_name: false,
            distance: 369,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.5703125,
              y: 0.8671875,
              k: 0.4980392156862745,
            },
            value: "cmyk(0, 57, 87, 50)",
            c: 0,
            m: 57,
            y: 87,
            k: 50,
          },
          XYZ: {
            fraction: {
              X: 0.2961713725490196,
              Y: 0.2657890196078431,
              Z: 0.0987643137254902,
            },
            value: "XYZ(30, 27, 10)",
            X: 30,
            Y: 27,
            Z: 10,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=803711",
            named: "https://www.thecolorapi.com/id?format=svg&hex=803711",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=803711" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#855C10", clean: "855C10" },
          rgb: {
            fraction: {
              r: 0.5215686274509804,
              g: 0.3607843137254902,
              b: 0.06274509803921569,
            },
            r: 133,
            g: 92,
            b: 16,
            value: "rgb(133, 92, 16)",
          },
          hsl: {
            fraction: {
              h: 0.10826210826210825,
              s: 0.785234899328859,
              l: 0.29215686274509806,
            },
            h: 39,
            s: 79,
            l: 29,
            value: "hsl(39, 79%, 29%)",
          },
          hsv: {
            fraction: {
              h: 0.10826210826210825,
              s: 0.8796992481203008,
              v: 0.5215686274509804,
            },
            value: "hsv(39, 88%, 52%)",
            h: 39,
            s: 88,
            v: 52,
          },
          name: {
            value: "Korma",
            closest_named_hex: "#8F4B0E",
            exact_match_name: false,
            distance: 685,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.30827067669172925,
              y: 0.8796992481203008,
              k: 0.4784313725490196,
            },
            value: "cmyk(0, 31, 88, 48)",
            c: 0,
            m: 31,
            y: 88,
            k: 48,
          },
          XYZ: {
            fraction: {
              X: 0.355436862745098,
              Y: 0.3734486274509804,
              Z: 0.11271098039215686,
            },
            value: "XYZ(36, 37, 11)",
            X: 36,
            Y: 37,
            Z: 11,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=855C10",
            named: "https://www.thecolorapi.com/id?format=svg&hex=855C10",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=855C10" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#8A8410", clean: "8A8410" },
          rgb: {
            fraction: {
              r: 0.5411764705882353,
              g: 0.5176470588235295,
              b: 0.06274509803921569,
            },
            r: 138,
            g: 132,
            b: 16,
            value: "rgb(138, 132, 16)",
          },
          hsl: {
            fraction: {
              h: 0.1584699453551912,
              s: 0.7922077922077922,
              l: 0.30196078431372547,
            },
            h: 57,
            s: 79,
            l: 30,
            value: "hsl(57, 79%, 30%)",
          },
          hsv: {
            fraction: {
              h: 0.1584699453551912,
              s: 0.8840579710144928,
              v: 0.5411764705882353,
            },
            value: "hsv(57, 88%, 54%)",
            h: 57,
            s: 88,
            v: 54,
          },
          name: {
            value: "Corn Harvest",
            closest_named_hex: "#8B6B0B",
            exact_match_name: false,
            distance: 1281,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.043478260869565064,
              y: 0.8840579710144928,
              k: 0.45882352941176474,
            },
            value: "cmyk(0, 4, 88, 46)",
            c: 0,
            m: 4,
            y: 88,
            k: 46,
          },
          XYZ: {
            fraction: {
              X: 0.4196172549019608,
              Y: 0.4898054901960784,
              Z: 0.13178745098039216,
            },
            value: "XYZ(42, 49, 13)",
            X: 42,
            Y: 49,
            Z: 13,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8A8410",
            named: "https://www.thecolorapi.com/id?format=svg&hex=8A8410",
          },
          contrast: { value: "#000000" },
          _links: { self: { href: "/id?hex=8A8410" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#709010", clean: "709010" },
          rgb: {
            fraction: {
              r: 0.4392156862745098,
              g: 0.5647058823529412,
              b: 0.06274509803921569,
            },
            r: 112,
            g: 144,
            b: 16,
            value: "rgb(112, 144, 16)",
          },
          hsl: {
            fraction: { h: 0.20833333333333337, s: 0.8, l: 0.3137254901960784 },
            h: 75,
            s: 80,
            l: 31,
            value: "hsl(75, 80%, 31%)",
          },
          hsv: {
            fraction: {
              h: 0.20833333333333337,
              s: 0.8888888888888888,
              v: 0.5647058823529412,
            },
            value: "hsv(75, 89%, 56%)",
            h: 75,
            s: 89,
            v: 56,
          },
          name: {
            value: "Christi",
            closest_named_hex: "#67A712",
            exact_match_name: false,
            distance: 1002,
          },
          cmyk: {
            fraction: {
              c: 0.2222222222222222,
              m: 0,
              y: 0.8888888888888888,
              k: 0.43529411764705883,
            },
            value: "cmyk(22, 0, 89, 44)",
            c: 22,
            m: 0,
            y: 89,
            k: 44,
          },
          XYZ: {
            fraction: {
              X: 0.394396862745098,
              Y: 0.5017850980392157,
              Z: 0.13542901960784315,
            },
            value: "XYZ(39, 50, 14)",
            X: 39,
            Y: 50,
            Z: 14,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=709010",
            named: "https://www.thecolorapi.com/id?format=svg&hex=709010",
          },
          contrast: { value: "#000000" },
          _links: { self: { href: "/id?hex=709010" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=analogic&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=analogic&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=analogic&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "complement",
      count: "5",
      colors: [
        {
          hex: { value: "#116367", clean: "116367" },
          rgb: {
            fraction: {
              r: 0.06666666666666667,
              g: 0.38823529411764707,
              b: 0.403921568627451,
            },
            r: 17,
            g: 99,
            b: 103,
            value: "rgb(17, 99, 103)",
          },
          hsl: {
            fraction: {
              h: 0.507751937984496,
              s: 0.7166666666666667,
              l: 0.23529411764705882,
            },
            h: 183,
            s: 72,
            l: 24,
            value: "hsl(183, 72%, 24%)",
          },
          hsv: {
            fraction: {
              h: 0.507751937984496,
              s: 0.8349514563106797,
              v: 0.403921568627451,
            },
            value: "hsv(183, 83%, 40%)",
            h: 183,
            s: 83,
            v: 40,
          },
          name: {
            value: "Genoa",
            closest_named_hex: "#15736B",
            exact_match_name: false,
            distance: 560,
          },
          cmyk: {
            fraction: {
              c: 0.8349514563106797,
              m: 0.038834951456310815,
              y: 0,
              k: 0.596078431372549,
            },
            value: "cmyk(83, 4, 0, 60)",
            c: 83,
            m: 4,
            y: 0,
            k: 60,
          },
          XYZ: {
            fraction: {
              X: 0.23923411764705882,
              Y: 0.3210023529411764,
              Z: 0.43149176470588235,
            },
            value: "XYZ(24, 32, 43)",
            X: 24,
            Y: 32,
            Z: 43,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116367",
            named: "https://www.thecolorapi.com/id?format=svg&hex=116367",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=116367" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#116F74", clean: "116F74" },
          rgb: {
            fraction: {
              r: 0.06666666666666667,
              g: 0.43529411764705883,
              b: 0.4549019607843137,
            },
            r: 17,
            g: 111,
            b: 116,
            value: "rgb(17, 111, 116)",
          },
          hsl: {
            fraction: {
              h: 0.5084175084175084,
              s: 0.7443609022556391,
              l: 0.2607843137254902,
            },
            h: 183,
            s: 74,
            l: 26,
            value: "hsl(183, 74%, 26%)",
          },
          hsv: {
            fraction: {
              h: 0.5084175084175084,
              s: 0.853448275862069,
              v: 0.4549019607843137,
            },
            value: "hsv(183, 85%, 45%)",
            h: 183,
            s: 85,
            v: 45,
          },
          name: {
            value: "Genoa",
            closest_named_hex: "#15736B",
            exact_match_name: false,
            distance: 531,
          },
          cmyk: {
            fraction: {
              c: 0.853448275862069,
              m: 0.04310344827586192,
              y: 0,
              k: 0.5450980392156863,
            },
            value: "cmyk(85, 4, 0, 55)",
            c: 85,
            m: 4,
            y: 0,
            k: 55,
          },
          XYZ: {
            fraction: {
              X: 0.2652643137254902,
              Y: 0.35833960784313723,
              Z: 0.48555803921568624,
            },
            value: "XYZ(27, 36, 49)",
            X: 27,
            Y: 36,
            Z: 49,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116F74",
            named: "https://www.thecolorapi.com/id?format=svg&hex=116F74",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=116F74" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#811711", clean: "811711" },
          rgb: {
            fraction: {
              r: 0.5058823529411764,
              g: 0.09019607843137255,
              b: 0.06666666666666667,
            },
            r: 129,
            g: 23,
            b: 17,
            value: "rgb(129, 23, 17)",
          },
          hsl: {
            fraction: {
              h: 0.008928571428571508,
              s: 0.767123287671233,
              l: 0.28627450980392155,
            },
            h: 3,
            s: 77,
            l: 29,
            value: "hsl(3, 77%, 29%)",
          },
          hsv: {
            fraction: {
              h: 0.008928571428571508,
              s: 0.8682170542635659,
              v: 0.5058823529411764,
            },
            value: "hsv(3, 87%, 51%)",
            h: 3,
            s: 87,
            v: 51,
          },
          name: {
            value: "Pueblo",
            closest_named_hex: "#7D2C14",
            exact_match_name: false,
            distance: 808,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8217054263565892,
              y: 0.8682170542635659,
              k: 0.49411764705882355,
            },
            value: "cmyk(0, 82, 87, 49)",
            c: 0,
            m: 82,
            y: 87,
            k: 49,
          },
          XYZ: {
            fraction: {
              X: 0.2529133333333333,
              Y: 0.1768721568627451,
              Z: 0.08388156862745098,
            },
            value: "XYZ(25, 18, 8)",
            X: 25,
            Y: 18,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=811711",
            named: "https://www.thecolorapi.com/id?format=svg&hex=811711",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=811711" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#8E1710", clean: "8E1710" },
          rgb: {
            fraction: {
              r: 0.5568627450980392,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 142,
            g: 23,
            b: 16,
            value: "rgb(142, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.7974683544303798,
              l: 0.30980392156862746,
            },
            h: 3,
            s: 80,
            l: 31,
            value: "hsl(3, 80%, 31%)",
          },
          hsv: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.8873239436619719,
              v: 0.5568627450980392,
            },
            value: "hsv(3, 89%, 56%)",
            h: 3,
            s: 89,
            v: 56,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 261,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8380281690140845,
              y: 0.8873239436619719,
              k: 0.44313725490196076,
            },
            value: "cmyk(0, 84, 89, 44)",
            c: 0,
            m: 84,
            y: 89,
            k: 44,
          },
          XYZ: {
            fraction: {
              X: 0.2732298039215686,
              Y: 0.18742745098039215,
              Z: 0.08113803921568627,
            },
            value: "XYZ(27, 19, 8)",
            X: 27,
            Y: 19,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=8E1710" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#9B1710", clean: "9B1710" },
          rgb: {
            fraction: {
              r: 0.6078431372549019,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 155,
            g: 23,
            b: 16,
            value: "rgb(155, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.8128654970760234,
              l: 0.3352941176470588,
            },
            h: 3,
            s: 81,
            l: 34,
            value: "hsl(3, 81%, 34%)",
          },
          hsv: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.896774193548387,
              v: 0.6078431372549019,
            },
            value: "hsv(3, 90%, 61%)",
            h: 3,
            s: 90,
            v: 61,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 184,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8516129032258064,
              y: 0.896774193548387,
              k: 0.3921568627450981,
            },
            value: "cmyk(0, 85, 90, 39)",
            c: 0,
            m: 85,
            y: 90,
            k: 39,
          },
          XYZ: {
            fraction: {
              X: 0.2942541176470588,
              Y: 0.19826588235294118,
              Z: 0.08212196078431372,
            },
            value: "XYZ(29, 20, 8)",
            X: 29,
            Y: 20,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=9B1710" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=complement&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=complement&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=complement&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "analogic-complement",
      count: "5",
      colors: [
        {
          hex: { value: "#104649", clean: "104649" },
          rgb: {
            fraction: {
              r: 0.06274509803921569,
              g: 0.27450980392156865,
              b: 0.28627450980392155,
            },
            r: 16,
            g: 70,
            b: 73,
            value: "rgb(16, 70, 73)",
          },
          hsl: {
            fraction: {
              h: 0.5087719298245612,
              s: 0.6404494382022472,
              l: 0.17450980392156862,
            },
            h: 183,
            s: 64,
            l: 17,
            value: "hsl(183, 64%, 17%)",
          },
          hsv: {
            fraction: {
              h: 0.5087719298245612,
              s: 0.7808219178082192,
              v: 0.28627450980392155,
            },
            value: "hsv(183, 78%, 29%)",
            h: 183,
            s: 78,
            v: 29,
          },
          name: {
            value: "Eden",
            closest_named_hex: "#105852",
            exact_match_name: false,
            distance: 943,
          },
          cmyk: {
            fraction: {
              c: 0.7808219178082192,
              m: 0.04109589041095876,
              y: 0,
              k: 0.7137254901960784,
            },
            value: "cmyk(78, 4, 0, 71)",
            c: 78,
            m: 4,
            y: 0,
            k: 71,
          },
          XYZ: {
            fraction: {
              X: 0.17571333333333333,
              Y: 0.2303380392156863,
              Z: 0.3060364705882353,
            },
            value: "XYZ(18, 23, 31)",
            X: 18,
            Y: 23,
            Z: 31,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=104649",
            named: "https://www.thecolorapi.com/id?format=svg&hex=104649",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=104649" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#115C60", clean: "115C60" },
          rgb: {
            fraction: {
              r: 0.06666666666666667,
              g: 0.3607843137254902,
              b: 0.3764705882352941,
            },
            r: 17,
            g: 92,
            b: 96,
            value: "rgb(17, 92, 96)",
          },
          hsl: {
            fraction: {
              h: 0.5084388185654007,
              s: 0.6991150442477877,
              l: 0.22156862745098038,
            },
            h: 183,
            s: 70,
            l: 22,
            value: "hsl(183, 70%, 22%)",
          },
          hsv: {
            fraction: {
              h: 0.5084388185654007,
              s: 0.8229166666666667,
              v: 0.3764705882352941,
            },
            value: "hsv(183, 82%, 38%)",
            h: 183,
            s: 82,
            v: 38,
          },
          name: {
            value: "Eden",
            closest_named_hex: "#105852",
            exact_match_name: false,
            distance: 325,
          },
          cmyk: {
            fraction: {
              c: 0.8229166666666667,
              m: 0.04166666666666652,
              y: 0,
              k: 0.6235294117647059,
            },
            value: "cmyk(82, 4, 0, 62)",
            c: 82,
            m: 4,
            y: 0,
            k: 62,
          },
          XYZ: {
            fraction: {
              X: 0.2244627450980392,
              Y: 0.2993874509803921,
              Z: 0.40212745098039215,
            },
            value: "XYZ(22, 30, 40)",
            X: 22,
            Y: 30,
            Z: 40,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=115C60",
            named: "https://www.thecolorapi.com/id?format=svg&hex=115C60",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=115C60" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#857F10", clean: "857F10" },
          rgb: {
            fraction: {
              r: 0.5215686274509804,
              g: 0.4980392156862745,
              b: 0.06274509803921569,
            },
            r: 133,
            g: 127,
            b: 16,
            value: "rgb(133, 127, 16)",
          },
          hsl: {
            fraction: {
              h: 0.158119658119658,
              s: 0.785234899328859,
              l: 0.29215686274509806,
            },
            h: 57,
            s: 79,
            l: 29,
            value: "hsl(57, 79%, 29%)",
          },
          hsv: {
            fraction: {
              h: 0.158119658119658,
              s: 0.8796992481203008,
              v: 0.5215686274509804,
            },
            value: "hsv(57, 88%, 52%)",
            h: 57,
            s: 88,
            v: 52,
          },
          name: {
            value: "Olivetone",
            closest_named_hex: "#716E10",
            exact_match_name: false,
            distance: 1053,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.04511278195488727,
              y: 0.8796992481203008,
              k: 0.4784313725490196,
            },
            value: "cmyk(0, 5, 88, 48)",
            c: 0,
            m: 5,
            y: 88,
            k: 48,
          },
          XYZ: {
            fraction: {
              X: 0.40451921568627447,
              Y: 0.47161333333333333,
              Z: 0.12907176470588236,
            },
            value: "XYZ(40, 47, 13)",
            X: 40,
            Y: 47,
            Z: 13,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=857F10",
            named: "https://www.thecolorapi.com/id?format=svg&hex=857F10",
          },
          contrast: { value: "#000000" },
          _links: { self: { href: "/id?hex=857F10" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#598A10", clean: "598A10" },
          rgb: {
            fraction: {
              r: 0.34901960784313724,
              g: 0.5411764705882353,
              b: 0.06274509803921569,
            },
            r: 89,
            g: 138,
            b: 16,
            value: "rgb(89, 138, 16)",
          },
          hsl: {
            fraction: {
              h: 0.23360655737704927,
              s: 0.7922077922077922,
              l: 0.30196078431372547,
            },
            h: 84,
            s: 79,
            l: 30,
            value: "hsl(84, 79%, 30%)",
          },
          hsv: {
            fraction: {
              h: 0.23360655737704927,
              s: 0.8840579710144928,
              v: 0.5411764705882353,
            },
            value: "hsv(84, 88%, 54%)",
            h: 84,
            s: 88,
            v: 54,
          },
          name: {
            value: "Vida Loca",
            closest_named_hex: "#549019",
            exact_match_name: false,
            distance: 1330,
          },
          cmyk: {
            fraction: {
              c: 0.3550724637681159,
              m: 0,
              y: 0.8840579710144928,
              k: 0.45882352941176474,
            },
            value: "cmyk(36, 0, 88, 46)",
            c: 36,
            m: 0,
            y: 88,
            k: 46,
          },
          XYZ: {
            fraction: {
              X: 0.3487858823529411,
              Y: 0.46578117647058814,
              Z: 0.1308835294117647,
            },
            value: "XYZ(35, 47, 13)",
            X: 35,
            Y: 47,
            Z: 13,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=598A10",
            named: "https://www.thecolorapi.com/id?format=svg&hex=598A10",
          },
          contrast: { value: "#000000" },
          _links: { self: { href: "/id?hex=598A10" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#239010", clean: "239010" },
          rgb: {
            fraction: {
              r: 0.13725490196078433,
              g: 0.5647058823529412,
              b: 0.06274509803921569,
            },
            r: 35,
            g: 144,
            b: 16,
            value: "rgb(35, 144, 16)",
          },
          hsl: {
            fraction: { h: 0.3085937499999999, s: 0.8, l: 0.3137254901960784 },
            h: 111,
            s: 80,
            l: 31,
            value: "hsl(111, 80%, 31%)",
          },
          hsv: {
            fraction: {
              h: 0.3085937499999999,
              s: 0.8888888888888888,
              v: 0.5647058823529412,
            },
            value: "hsv(111, 89%, 56%)",
            h: 111,
            s: 89,
            v: 56,
          },
          name: {
            value: "Slimy Green",
            closest_named_hex: "#299617",
            exact_match_name: false,
            distance: 771,
          },
          cmyk: {
            fraction: {
              c: 0.7569444444444445,
              m: 0,
              y: 0.8888888888888888,
              k: 0.43529411764705883,
            },
            value: "cmyk(76, 0, 89, 44)",
            c: 76,
            m: 0,
            y: 89,
            k: 44,
          },
          XYZ: {
            fraction: {
              X: 0.26986823529411763,
              Y: 0.4375882352941176,
              Z: 0.12960117647058825,
            },
            value: "XYZ(27, 44, 13)",
            X: 27,
            Y: 44,
            Z: 13,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=239010",
            named: "https://www.thecolorapi.com/id?format=svg&hex=239010",
          },
          contrast: { value: "#000000" },
          _links: { self: { href: "/id?hex=239010" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=analogic-complement&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=analogic-complement&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=analogic-complement&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "triad",
      count: "5",
      colors: [
        {
          hex: { value: "#116714", clean: "116714" },
          rgb: {
            fraction: {
              r: 0.06666666666666667,
              g: 0.403921568627451,
              b: 0.0784313725490196,
            },
            r: 17,
            g: 103,
            b: 20,
            value: "rgb(17, 103, 20)",
          },
          hsl: {
            fraction: {
              h: 0.3391472868217055,
              s: 0.7166666666666667,
              l: 0.23529411764705882,
            },
            h: 122,
            s: 72,
            l: 24,
            value: "hsl(122, 72%, 24%)",
          },
          hsv: {
            fraction: {
              h: 0.3391472868217055,
              s: 0.8349514563106797,
              v: 0.403921568627451,
            },
            value: "hsv(122, 83%, 40%)",
            h: 122,
            s: 83,
            v: 40,
          },
          name: {
            value: "Green House",
            closest_named_hex: "#24500F",
            exact_match_name: false,
            distance: 1831,
          },
          cmyk: {
            fraction: {
              c: 0.8349514563106797,
              m: 0,
              y: 0.8058252427184468,
              k: 0.596078431372549,
            },
            value: "cmyk(83, 0, 81, 60)",
            c: 83,
            m: 0,
            y: 81,
            k: 60,
          },
          XYZ: {
            fraction: {
              X: 0.18609254901960784,
              Y: 0.30872078431372546,
              Z: 0.12398313725490195,
            },
            value: "XYZ(19, 31, 12)",
            X: 19,
            Y: 31,
            Z: 12,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116714",
            named: "https://www.thecolorapi.com/id?format=svg&hex=116714",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=116714" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#181174", clean: "181174" },
          rgb: {
            fraction: {
              r: 0.09411764705882353,
              g: 0.06666666666666667,
              b: 0.4549019607843137,
            },
            r: 24,
            g: 17,
            b: 116,
            value: "rgb(24, 17, 116)",
          },
          hsl: {
            fraction: {
              h: 0.6784511784511786,
              s: 0.7443609022556391,
              l: 0.2607843137254902,
            },
            h: 244,
            s: 74,
            l: 26,
            value: "hsl(244, 74%, 26%)",
          },
          hsv: {
            fraction: {
              h: 0.6784511784511786,
              s: 0.853448275862069,
              v: 0.4549019607843137,
            },
            value: "hsv(244, 85%, 45%)",
            h: 244,
            s: 85,
            v: 45,
          },
          name: {
            value: "Deep Koamaru",
            closest_named_hex: "#1B127B",
            exact_match_name: false,
            distance: 91,
          },
          cmyk: {
            fraction: {
              c: 0.793103448275862,
              m: 0.853448275862069,
              y: 0,
              k: 0.5450980392156863,
            },
            value: "cmyk(79, 85, 0, 55)",
            c: 79,
            m: 85,
            y: 0,
            k: 55,
          },
          XYZ: {
            fraction: {
              X: 0.14476392156862744,
              Y: 0.10053333333333332,
              Z: 0.44214745098039215,
            },
            value: "XYZ(14, 10, 44)",
            X: 14,
            Y: 10,
            Z: 44,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=181174",
            named: "https://www.thecolorapi.com/id?format=svg&hex=181174",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=181174" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#811711", clean: "811711" },
          rgb: {
            fraction: {
              r: 0.5058823529411764,
              g: 0.09019607843137255,
              b: 0.06666666666666667,
            },
            r: 129,
            g: 23,
            b: 17,
            value: "rgb(129, 23, 17)",
          },
          hsl: {
            fraction: {
              h: 0.008928571428571508,
              s: 0.767123287671233,
              l: 0.28627450980392155,
            },
            h: 3,
            s: 77,
            l: 29,
            value: "hsl(3, 77%, 29%)",
          },
          hsv: {
            fraction: {
              h: 0.008928571428571508,
              s: 0.8682170542635659,
              v: 0.5058823529411764,
            },
            value: "hsv(3, 87%, 51%)",
            h: 3,
            s: 87,
            v: 51,
          },
          name: {
            value: "Pueblo",
            closest_named_hex: "#7D2C14",
            exact_match_name: false,
            distance: 808,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8217054263565892,
              y: 0.8682170542635659,
              k: 0.49411764705882355,
            },
            value: "cmyk(0, 82, 87, 49)",
            c: 0,
            m: 82,
            y: 87,
            k: 49,
          },
          XYZ: {
            fraction: {
              X: 0.2529133333333333,
              Y: 0.1768721568627451,
              Z: 0.08388156862745098,
            },
            value: "XYZ(25, 18, 8)",
            X: 25,
            Y: 18,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=811711",
            named: "https://www.thecolorapi.com/id?format=svg&hex=811711",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=811711" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#8E1710", clean: "8E1710" },
          rgb: {
            fraction: {
              r: 0.5568627450980392,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 142,
            g: 23,
            b: 16,
            value: "rgb(142, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.7974683544303798,
              l: 0.30980392156862746,
            },
            h: 3,
            s: 80,
            l: 31,
            value: "hsl(3, 80%, 31%)",
          },
          hsv: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.8873239436619719,
              v: 0.5568627450980392,
            },
            value: "hsv(3, 89%, 56%)",
            h: 3,
            s: 89,
            v: 56,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 261,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8380281690140845,
              y: 0.8873239436619719,
              k: 0.44313725490196076,
            },
            value: "cmyk(0, 84, 89, 44)",
            c: 0,
            m: 84,
            y: 89,
            k: 44,
          },
          XYZ: {
            fraction: {
              X: 0.2732298039215686,
              Y: 0.18742745098039215,
              Z: 0.08113803921568627,
            },
            value: "XYZ(27, 19, 8)",
            X: 27,
            Y: 19,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=8E1710" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#9B1710", clean: "9B1710" },
          rgb: {
            fraction: {
              r: 0.6078431372549019,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 155,
            g: 23,
            b: 16,
            value: "rgb(155, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.8128654970760234,
              l: 0.3352941176470588,
            },
            h: 3,
            s: 81,
            l: 34,
            value: "hsl(3, 81%, 34%)",
          },
          hsv: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.896774193548387,
              v: 0.6078431372549019,
            },
            value: "hsv(3, 90%, 61%)",
            h: 3,
            s: 90,
            v: 61,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 184,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8516129032258064,
              y: 0.896774193548387,
              k: 0.3921568627450981,
            },
            value: "cmyk(0, 85, 90, 39)",
            c: 0,
            m: 85,
            y: 90,
            k: 39,
          },
          XYZ: {
            fraction: {
              X: 0.2942541176470588,
              Y: 0.19826588235294118,
              Z: 0.08212196078431372,
            },
            value: "XYZ(29, 20, 8)",
            X: 29,
            Y: 20,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=9B1710" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=triad&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=triad&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=triad&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
    {
      mode: "quad",
      count: "5",
      colors: [
        {
          hex: { value: "#386711", clean: "386711" },
          rgb: {
            fraction: {
              r: 0.2196078431372549,
              g: 0.403921568627451,
              b: 0.06666666666666667,
            },
            r: 56,
            g: 103,
            b: 17,
            value: "rgb(56, 103, 17)",
          },
          hsl: {
            fraction: {
              h: 0.25775193798449625,
              s: 0.7166666666666667,
              l: 0.23529411764705882,
            },
            h: 93,
            s: 72,
            l: 24,
            value: "hsl(93, 72%, 24%)",
          },
          hsv: {
            fraction: {
              h: 0.25775193798449625,
              s: 0.8349514563106797,
              v: 0.403921568627451,
            },
            value: "hsv(93, 83%, 40%)",
            h: 93,
            s: 83,
            v: 40,
          },
          name: {
            value: "Dell",
            closest_named_hex: "#396413",
            exact_match_name: false,
            distance: 178,
          },
          cmyk: {
            fraction: {
              c: 0.4563106796116505,
              m: 0,
              y: 0.8349514563106797,
              k: 0.596078431372549,
            },
            value: "cmyk(46, 0, 83, 60)",
            c: 46,
            m: 0,
            y: 83,
            k: 60,
          },
          XYZ: {
            fraction: {
              X: 0.24704196078431373,
              Y: 0.34038666666666667,
              Z: 0.11575254901960785,
            },
            value: "XYZ(25, 34, 12)",
            X: 25,
            Y: 34,
            Z: 12,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=386711",
            named: "https://www.thecolorapi.com/id?format=svg&hex=386711",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=386711" } },
          _embedded: {},
          target: "secondary",
        },
        {
          hex: { value: "#116F74", clean: "116F74" },
          rgb: {
            fraction: {
              r: 0.06666666666666667,
              g: 0.43529411764705883,
              b: 0.4549019607843137,
            },
            r: 17,
            g: 111,
            b: 116,
            value: "rgb(17, 111, 116)",
          },
          hsl: {
            fraction: {
              h: 0.5084175084175084,
              s: 0.7443609022556391,
              l: 0.2607843137254902,
            },
            h: 183,
            s: 74,
            l: 26,
            value: "hsl(183, 74%, 26%)",
          },
          hsv: {
            fraction: {
              h: 0.5084175084175084,
              s: 0.853448275862069,
              v: 0.4549019607843137,
            },
            value: "hsv(183, 85%, 45%)",
            h: 183,
            s: 85,
            v: 45,
          },
          name: {
            value: "Genoa",
            closest_named_hex: "#15736B",
            exact_match_name: false,
            distance: 531,
          },
          cmyk: {
            fraction: {
              c: 0.853448275862069,
              m: 0.04310344827586192,
              y: 0,
              k: 0.5450980392156863,
            },
            value: "cmyk(85, 4, 0, 55)",
            c: 85,
            m: 4,
            y: 0,
            k: 55,
          },
          XYZ: {
            fraction: {
              X: 0.2652643137254902,
              Y: 0.35833960784313723,
              Z: 0.48555803921568624,
            },
            value: "XYZ(27, 36, 49)",
            X: 27,
            Y: 36,
            Z: 49,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=116F74",
            named: "https://www.thecolorapi.com/id?format=svg&hex=116F74",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=116F74" } },
          _embedded: {},
          target: "error",
        },
        {
          hex: { value: "#4E1181", clean: "4E1181" },
          rgb: {
            fraction: {
              r: 0.3058823529411765,
              g: 0.06666666666666667,
              b: 0.5058823529411764,
            },
            r: 78,
            g: 17,
            b: 129,
            value: "rgb(78, 17, 129)",
          },
          hsl: {
            fraction: {
              h: 0.7574404761904764,
              s: 0.767123287671233,
              l: 0.28627450980392155,
            },
            h: 273,
            s: 77,
            l: 29,
            value: "hsl(273, 77%, 29%)",
          },
          hsv: {
            fraction: {
              h: 0.7574404761904764,
              s: 0.8682170542635659,
              v: 0.5058823529411764,
            },
            value: "hsv(273, 87%, 51%)",
            h: 273,
            s: 87,
            v: 51,
          },
          name: {
            value: "Pixie Powder",
            closest_named_hex: "#391285",
            exact_match_name: false,
            distance: 630,
          },
          cmyk: {
            fraction: {
              c: 0.39534883720930225,
              m: 0.8682170542635659,
              y: 0,
              k: 0.49411764705882355,
            },
            value: "cmyk(40, 87, 0, 49)",
            c: 40,
            m: 87,
            y: 0,
            k: 49,
          },
          XYZ: {
            fraction: {
              X: 0.24129764705882353,
              Y: 0.14923529411764705,
              Z: 0.49469137254901957,
            },
            value: "XYZ(24, 15, 49)",
            X: 24,
            Y: 15,
            Z: 49,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=4E1181",
            named: "https://www.thecolorapi.com/id?format=svg&hex=4E1181",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=4E1181" } },
          _embedded: {},
          target: "warning",
        },
        {
          hex: { value: "#8E1710", clean: "8E1710" },
          rgb: {
            fraction: {
              r: 0.5568627450980392,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 142,
            g: 23,
            b: 16,
            value: "rgb(142, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.7974683544303798,
              l: 0.30980392156862746,
            },
            h: 3,
            s: 80,
            l: 31,
            value: "hsl(3, 80%, 31%)",
          },
          hsv: {
            fraction: {
              h: 0.00925925925925919,
              s: 0.8873239436619719,
              v: 0.5568627450980392,
            },
            value: "hsv(3, 89%, 56%)",
            h: 3,
            s: 89,
            v: 56,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 261,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8380281690140845,
              y: 0.8873239436619719,
              k: 0.44313725490196076,
            },
            value: "cmyk(0, 84, 89, 44)",
            c: 0,
            m: 84,
            y: 89,
            k: 44,
          },
          XYZ: {
            fraction: {
              X: 0.2732298039215686,
              Y: 0.18742745098039215,
              Z: 0.08113803921568627,
            },
            value: "XYZ(27, 19, 8)",
            X: 27,
            Y: 19,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=8E1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=8E1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=8E1710" } },
          _embedded: {},
          target: "info",
        },
        {
          hex: { value: "#9B1710", clean: "9B1710" },
          rgb: {
            fraction: {
              r: 0.6078431372549019,
              g: 0.09019607843137255,
              b: 0.06274509803921569,
            },
            r: 155,
            g: 23,
            b: 16,
            value: "rgb(155, 23, 16)",
          },
          hsl: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.8128654970760234,
              l: 0.3352941176470588,
            },
            h: 3,
            s: 81,
            l: 34,
            value: "hsl(3, 81%, 34%)",
          },
          hsv: {
            fraction: {
              h: 0.008393285371702697,
              s: 0.896774193548387,
              v: 0.6078431372549019,
            },
            value: "hsv(3, 90%, 61%)",
            h: 3,
            s: 90,
            v: 61,
          },
          name: {
            value: "Tamarillo",
            closest_named_hex: "#991613",
            exact_match_name: false,
            distance: 184,
          },
          cmyk: {
            fraction: {
              c: 0,
              m: 0.8516129032258064,
              y: 0.896774193548387,
              k: 0.3921568627450981,
            },
            value: "cmyk(0, 85, 90, 39)",
            c: 0,
            m: 85,
            y: 90,
            k: 39,
          },
          XYZ: {
            fraction: {
              X: 0.2942541176470588,
              Y: 0.19826588235294118,
              Z: 0.08212196078431372,
            },
            value: "XYZ(29, 20, 8)",
            X: 29,
            Y: 20,
            Z: 8,
          },
          image: {
            bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=9B1710",
            named: "https://www.thecolorapi.com/id?format=svg&hex=9B1710",
          },
          contrast: { value: "#ffffff" },
          _links: { self: { href: "/id?hex=9B1710" } },
          _embedded: {},
          target: "success",
        },
      ],
      seed: {
        hex: { value: "#881610", clean: "881610" },
        rgb: {
          fraction: {
            r: 0.5333333333333333,
            g: 0.08627450980392157,
            b: 0.06274509803921569,
          },
          r: 136,
          g: 22,
          b: 16,
          value: "rgb(136, 22, 16)",
        },
        hsl: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.7894736842105263,
            l: 0.2980392156862745,
          },
          h: 3,
          s: 79,
          l: 30,
          value: "hsl(3, 79%, 30%)",
        },
        hsv: {
          fraction: {
            h: 0.008333333333333304,
            s: 0.8823529411764706,
            v: 0.5333333333333333,
          },
          value: "hsv(3, 88%, 53%)",
          h: 3,
          s: 88,
          v: 53,
        },
        name: {
          value: "Tamarillo",
          closest_named_hex: "#991613",
          exact_match_name: false,
          distance: 486,
        },
        cmyk: {
          fraction: {
            c: 0,
            m: 0.838235294117647,
            y: 0.8823529411764706,
            k: 0.4666666666666667,
          },
          value: "cmyk(0, 84, 88, 47)",
          c: 0,
          m: 84,
          y: 88,
          k: 47,
        },
        XYZ: {
          fraction: {
            X: 0.2621239215686274,
            Y: 0.17962039215686276,
            Z: 0.08021647058823529,
          },
          value: "XYZ(26, 18, 8)",
          X: 26,
          Y: 18,
          Z: 8,
        },
        image: {
          bare: "https://www.thecolorapi.com/id?format=svg&named=false&hex=881610",
          named: "https://www.thecolorapi.com/id?format=svg&hex=881610",
        },
        contrast: { value: "#ffffff" },
        _links: { self: { href: "/id?hex=881610" } },
        _embedded: {},
      },
      image: {
        bare: "https://www.thecolorapi.com/scheme?format=svg&named=false&hex=881610&mode=quad&count=5",
        named:
          "https://www.thecolorapi.com/scheme?format=svg&hex=881610&mode=quad&count=5",
      },
      _links: {
        self: "/scheme?hex=881610&mode=quad&count=5",
        schemes: {
          monochrome: "/scheme?hex=881610&mode=monochrome&count=5",
          "monochrome-dark": "/scheme?hex=881610&mode=monochrome-dark&count=5",
          "monochrome-light":
            "/scheme?hex=881610&mode=monochrome-light&count=5",
          analogic: "/scheme?hex=881610&mode=analogic&count=5",
          complement: "/scheme?hex=881610&mode=complement&count=5",
          "analogic-complement":
            "/scheme?hex=881610&mode=analogic-complement&count=5",
          triad: "/scheme?hex=881610&mode=triad&count=5",
          quad: "/scheme?hex=881610&mode=quad&count=5",
        },
      },
      _embedded: {},
    },
  ],
  banners: [],
  themes: [
    { id: "4", name: "GreenMediumMono" },
    { id: "2", name: "BlueBrownish" },
    { id: "1", name: "default" },
    { id: "3", name: "DarkGreen" },
  ],
  font: "'PT Sans Caption'",
  menuPosition: "top",
  menuAnchors: ["Explore", "Members", "Worship"],
  unstable_sxConfig: {
    border: { themeKey: "borders" },
    borderTop: { themeKey: "borders" },
    borderRight: { themeKey: "borders" },
    borderBottom: { themeKey: "borders" },
    borderLeft: { themeKey: "borders" },
    borderColor: { themeKey: "palette" },
    borderTopColor: { themeKey: "palette" },
    borderRightColor: { themeKey: "palette" },
    borderBottomColor: { themeKey: "palette" },
    borderLeftColor: { themeKey: "palette" },
    borderRadius: { themeKey: "shape.borderRadius" },
    color: { themeKey: "palette" },
    bgcolor: { themeKey: "palette", cssProperty: "backgroundColor" },
    backgroundColor: { themeKey: "palette" },
    p: {},
    pt: {},
    pr: {},
    pb: {},
    pl: {},
    px: {},
    py: {},
    padding: {},
    paddingTop: {},
    paddingRight: {},
    paddingBottom: {},
    paddingLeft: {},
    paddingX: {},
    paddingY: {},
    paddingInline: {},
    paddingInlineStart: {},
    paddingInlineEnd: {},
    paddingBlock: {},
    paddingBlockStart: {},
    paddingBlockEnd: {},
    m: {},
    mt: {},
    mr: {},
    mb: {},
    ml: {},
    mx: {},
    my: {},
    margin: {},
    marginTop: {},
    marginRight: {},
    marginBottom: {},
    marginLeft: {},
    marginX: {},
    marginY: {},
    marginInline: {},
    marginInlineStart: {},
    marginInlineEnd: {},
    marginBlock: {},
    marginBlockStart: {},
    marginBlockEnd: {},
    displayPrint: { cssProperty: false },
    display: {},
    overflow: {},
    textOverflow: {},
    visibility: {},
    whiteSpace: {},
    flexBasis: {},
    flexDirection: {},
    flexWrap: {},
    justifyContent: {},
    alignItems: {},
    alignContent: {},
    order: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    alignSelf: {},
    justifyItems: {},
    justifySelf: {},
    gap: {},
    rowGap: {},
    columnGap: {},
    gridColumn: {},
    gridRow: {},
    gridAutoFlow: {},
    gridAutoColumns: {},
    gridAutoRows: {},
    gridTemplateColumns: {},
    gridTemplateRows: {},
    gridTemplateAreas: {},
    gridArea: {},
    position: {},
    zIndex: { themeKey: "zIndex" },
    top: {},
    right: {},
    bottom: {},
    left: {},
    boxShadow: { themeKey: "shadows" },
    width: {},
    maxWidth: {},
    minWidth: {},
    height: {},
    maxHeight: {},
    minHeight: {},
    boxSizing: {},
    fontFamily: { themeKey: "typography" },
    fontSize: { themeKey: "typography" },
    fontStyle: { themeKey: "typography" },
    fontWeight: { themeKey: "typography" },
    letterSpacing: {},
    textTransform: {},
    lineHeight: {},
    textAlign: {},
    typography: { cssProperty: false, themeKey: "typography" },
  },
  bgCount: 0,
  topStyle: {
    maxWidth: 300,
    margin: "auto",
    justifyContent: "center",
    height: 45,
    padding: 10,
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  adminName: "",
  adminPassword: "",
  button: {
    fontFamily: "'PT Sans Caption'",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    textDecoration: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 2,
    padding: 3,
    color: "rgb(255, 255, 255)",
    backgroundColor: "#881610",
  },
  label: { whiteSpace: "nowrap" },
};
