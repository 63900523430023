import React, { useState, useEffect } from "react";
import { useTheme, styled } from "@mui/material/styles";

const Test = () => {
  const theme = useTheme();
  return (
    <div>
      <div style={{ backgroundColor: "#fff" }}>
        <pre>{JSON.stringify(theme, null, 3)}</pre>
      </div>
    </div>
  );
};

export default Test;
